import axios from 'axios';
import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';

const uri = process.env.REACT_APP_BACK_URL || 'http://localhost:8080';
const token = localStorage.getItem('Token');
const config = {
	headers: {
		xtoken: `${token}`,
	},
};

export function isAuthenticated() {
	const token = localStorage.getItem('Token');
	if (!token) return false;
	if (validateExpDate(token)) return true;
}

function validateExpDate(token) {
	const decoded = jwtDecode(token);
	if (!decoded) return false;
	if (decoded.exp * 1000 < Date.now()) {
		console.log('Debes iniciar sesión nuevamente!');

		localStorage.clear();
		setTimeout(() => {
			window.location.replace('/markethome');
		}, 2000);
		return false;
	}
	
	return true;
}

export function getUserID() {
	const token = localStorage.getItem('Token');
	return jwtDecode(token).id;
}

export function getToken() {
	const token = localStorage.getItem('Token');
	return token;
}

export function logout() {
	localStorage.removeItem('Token');
	localStorage.removeItem('Usuario');

	return true;
}

export function replaceRoute() {
	if (!isAuthenticated()) {
		window.location.replace('/login');
		return (
			<div>
				<p>Debes estar logueado para ingresar aquí!</p>
			</div>
		);
	}
}

export async function recuperarContraseña(correo, newPassword) {
	const actualizar = {
		correo,
		newPassword,
	};
	try {
		const { data } = await axios.post(`${uri}/api/password/olvido`, actualizar, config);
		console.log(data);
		toast.success(`${data.message}, revisa SPAM`);
	} catch (error) {
		toast.error('Ocurrió un error al intentar reestablecer la contraseña');
	}
}
