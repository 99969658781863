import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import { Tab, Tabs } from 'react-bootstrap';
import ModalNewProduct from '../../components/modals/modalNewProduct/ModalNewProduct';
import ModalEditProduct from '../../components/modals/modalEditProduct/ModalEditProduct';
import ModalNewAliance from '../../components/modals/modalNewAliance/ModalNewAliance';
import ModalEditAliance from '../../components/modals/modalEditAliance/ModalEditAliance';
import ModalEditPurchase from '../../components/modals/modalEditPurchase/ModalEditPurchase';
import ModalEditGiftcard from '../../components/modals/modalEditGiftcard/ModalEditGiftcard';
import ModalNewGiftcard from '../../components/modals/modalNewGiftcard/ModalNewGiftcard';
import ModalEditClient from '../../components/modals/modalEditClient/ModalEditClient';
import ModalNewClient from '../../components/modals/modalNewClient/ModalNewClient';
import ModalEditCategory from '../../components/modals/modalEditCategory/ModalEditCategory';
import ModalNewCategory from '../../components/modals/modalNewCategory/ModalNewCategory';
import ModalNewPurchase from '../../components/modals/modalNewPurchase/ModalNewPurchase';
import ModalEditUserCategory from '../../components/modals/modalEditUserCategory/ModalEditUserCategory';
import ModalNewUserCategory from '../../components/modals/modalNewUserCategory/ModalNewUserCategory';
import ModalNewMedioPago from '../../components/modals/modalNewMedioPago/ModalNewMedioPago';
import ModalEditMedioPago from '../../components/modals/modalEditMedioPago/ModalEditMedioPago';
import { isEditMode } from '../../utils/users/users';
import { getServicios } from '../../utils/products/products';
import {useNavigate} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Typography, Box } from '@mui/material';


import axios from 'axios';

import {
	getAllCategories,
	getCategories,
	getProductosInternos,
	getProductsByCategories,
	getUserCategories,
	searchAllProducts,
	searchProducts,
} from '../../utils/products/products';
import { searchAllAliances } from '../../utils/alianzas/alianzas';

import { formatDate } from '../../utils/validate/validate';
import { currencyDisplay } from '../../utils/cart/cart';

import { useDispatch, useSelector } from 'react-redux';
import { toggleAdminEditMode } from '../../features-slice/user/userSlice';
import { isAuthenticated } from '../../utils/auth/auth';

import UsersTable from './usersTable/UsersTable';
import ProductsTable from './productsTable/ProductsTable';
import PurchasesTable from './purchasesTable/PurchasesTable';
import AliancesTable from './aliancesTable/AliancesTable';
import GiftcardsTable from './giftcardsTable/GiftcardsTable';
import CategoriesTable from './categoriesTable/CategoriesTable';
import UserCategoriesTable from './userCategoriesTable/UserCategoriesTable';
import PurchasesWebTable from './purchasesWebTable/PuchasesWebTable';


import { getProducts, ajustarPrecios, ajustarPuntos, getMediosPago, searchMedios } from '../../utils/products/products';
import { getAliances } from '../../utils/alianzas/alianzas';
import { getUsers, searchAllUsers, capitalizeWord } from '../../utils/users/users';
import { getPurchases, searchAllPurchases, getWebPurchases, searchAllWebPurchases } from '../../utils/puchases/purchases';
import Pagination from '../../components/pagination/Pagination';
import Loader from '../../components/loader/Loader';
import { Toaster, toast } from 'react-hot-toast';
import './Dashboard.Module.css';
import { getGiftcards, searchAllGiftcards } from '../../utils/giftcards/giftcard';
import MediosPagoTable from './mediosPagoTable/MediosPagoTable';
import StockTable from './stockTable/StockTable';
import ServiciosTable from './serviciosTable/ServiciosTable';

export default function Dashboard() {
	const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user && user?.role === 'SUPERADMIN_ROLE';
	const isAdmin = user && user?.role === 'ADMIN_ROLE';
	const isNotAllowed = !isAdmin && !isSuperAdmin;
	const dispatch = useDispatch();
	const [modoMantenimiento, setModoMantenimiento] = React.useState(false);
	const navigate = useNavigate()

	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);

	//Estados para modales
	const [showModalNewCategory, setShowModalNewCategory] = React.useState(false);
	const [showModalEditCategory, setShowModalEditCategory] = React.useState(false);

	const [showModalNewUserCategory, setShowModalNewUserCategory] = React.useState(false);
	const [showModalEditUserCategory, setShowModalEditUserCategory] = React.useState(false);

	const [showModalNewClient, setShowModalNewClient] = React.useState(false);
	const [showModalEditClient, setShowModalEditClient] = React.useState(false);

	const [showModalNew, setShowModalNew] = React.useState(false);
	const [showModalEdit, setShowModalEdit] = React.useState(false);

	const [showModalNewPurchase, setShowModalNewPurchase] = React.useState(false);
	const [showModalEditPurchase, setShowModalEditPurchase] = React.useState(false);

	const [showModalNewAliance, setShowModalNewAliance] = React.useState(false);
	const [showModalEditAliance, setShowModalEditAliance] = React.useState(false);

	const [showModalNewGiftcard, setShowModalNewGiftcard] = React.useState(false);
	const [showModalEditGiftcard, setShowModalEditGiftcard] = React.useState(false);

	const [showModalNewMedio, setShowModalNewMedio] = React.useState(false);
	const [showModalEditMedio, setShowModalEditMedio] = React.useState(false);

	//logica y paginado para productos
	const [paginaActual, setPaginaActual] = React.useState(1);
	const productosPorPagina = 16;

	const mediosPagoPorPagina = 8

	const categoriaUsuarioPorPagina=8

	const giftCardPorPagina=8

	const ventasPorPagina=8

	const ventasMarketPorPagina=8

	const clientesPorPagina=8

	const paginadoProducts = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setProductosDesde(numeroPagina * 16 - 16);
	};
	const paginadoUsers = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setUsuariosDesde(numeroPagina * 8 - 8);
	};
	const paginadoPurchases = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setComprasDesde(numeroPagina * 8 - 8);
	};

	const paginadoWebPurchases = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setComprasWebDesde(numeroPagina * 8 - 8);
	};

	const paginadoAliances = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setAlianzasDesde(numeroPagina * 8 - 8);
	};
	const paginadoGiftcards = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setGiftcardDesde(numeroPagina * 8 - 8);
	};

	const paginadoCategories = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setCategoriasDesde(numeroPagina * 8 - 8);
	};

	const paginadoUserCategories = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setCategoriasUsuariosDesde(numeroPagina * 8 - 8);
	};

	const paginadoMediosPago = (numeroPagina) => {
		setPaginaActual(numeroPagina);
		setMediosDesde(numeroPagina * 8 - 8);
	};
	//Estados locales y "desde" para paginado desde el back
	const [products, setProducts] = React.useState([]);
	const [productosDesde, setProductosDesde] = React.useState(0);

	const [productosConsumoInterno, setProductosConsumoInterno] = React.useState([])

	const [servicios, setServicios] = React.useState([])

	const [purchases, setPurchases] = React.useState([]);
	const [comprasDesde, setComprasDesde] = React.useState(0);

	const [webPurchases, setWebPurchases] = React.useState([]);
	const [comprasWebDesde, setComprasWebDesde] = React.useState(0);

	const [users, setUsers] = React.useState([]);
	const [usuariosDesde, setUsuariosDesde] = React.useState(0);

	const [giftCards, setGiftcards] = React.useState([]);
	const [giftcardDesde, setGiftcardDesde] = React.useState(0);

	const [alianzas, setAlianzas] = React.useState([]);
	const [alianzasDesde, setAlianzasDesde] = React.useState(0);

	const [categorias, setCategorias] = React.useState([]);
	const [categoriasDesde, setCategoriasDesde] = React.useState(0);

	const [categoriasUsuarios, setCategoriasUsuarios] = React.useState([]);
	const [categoriasUsuariosDesde, setCategoriasUsuariosDesde] = React.useState(0);

	const [mediosPago, setMediosPago] = React.useState([]);
	const [mediosDesde, setMediosDesde] = React.useState(0);



	//Estados para busqueda
	const [terminoProducto, setTerminoProducto] = React.useState('');
	const [terminoConsumoInterno, setTerminoConsumoInterno] = React.useState("")
	const [terminoServicio, setTerminoServicio] = React.useState("")
	const [terminoUsuario, setTerminoUsuario] = React.useState('');
	const [terminoCompra, setTerminoCompra] = React.useState('');
	const [terminoCompraWeb, setTerminoCompraWeb] = React.useState('');
	const [terminoGiftcard, setTerminoGiftcard] = React.useState('');
	const [terminoAlianza, setTerminoAlianza] = React.useState('');
	const [terminoMedio, setTerminoMedio] = React.useState('');

	//Estados para mostrar detalles de elemento seleccionado
	const [selectedUser, setSelectedUser] = React.useState(null);
	const [selectedProduct, setSelectedProduct] = React.useState(null);
	const [selectedProductoInterno, setSelectedProductoInterno] = React.useState(null);
	const [selectedPurchase, setSelectedPurchase] = React.useState(null);
	const [selectedAliance, setSelectedAliance] = React.useState(null);
	const [selectedGiftcard, setSelectedGiftcard] = React.useState(null);
	const [selectedCategory, setSelectedCategory] = React.useState(null);
	const [selectedUserCategory, setSelectedUserCategory] = React.useState(null);
	const [selectedMedio, setSelectedMedio] = React.useState(null);
	const [selectedServicio, setSelectedServicio] = React.useState(null);



	//Funciones para manejar la seleccion de elementos
	const handleUserClick = (user) => {
		setSelectedUser(user);
		setSelectedProduct(null);
		setSelectedPurchase(null);
		setSelectedAliance(null);
		setSelectedGiftcard(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};
	const handleGiftcardClick = (giftcard) => {
		setSelectedGiftcard(giftcard);
		setSelectedUser(null);
		setSelectedProduct(null);
		setSelectedPurchase(null);
		setSelectedAliance(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};

	const handleProductClick = (product) => {
		setSelectedUser(null);
		setSelectedProduct(product);
		setSelectedPurchase(null);
		setSelectedAliance(null);
		setSelectedGiftcard(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};

	const handleProductoInternoClick = (product) => {
		setSelectedUser(null);
		setSelectedProduct(null)
		setSelectedProductoInterno(product);
		setSelectedPurchase(null);
		setSelectedAliance(null);
		setSelectedGiftcard(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};

	const handleServicioClick = (product) => {
		setSelectedUser(null);
		setSelectedProduct(null)
		setSelectedProductoInterno(null);
		setSelectedServicio(product)
		setSelectedPurchase(null);
		setSelectedAliance(null);
		setSelectedGiftcard(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};

	const handlePurchaseClick = (purchase) => {
		setSelectedUser(null);
		setSelectedProduct(null);
		setSelectedPurchase(purchase);
		setSelectedAliance(null);
		setSelectedGiftcard(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};

	const handleAlianceClick = (aliance) => {
		setSelectedAliance(aliance);
		setSelectedProduct(null);
		setSelectedPurchase(null);
		setSelectedUser(null);
		setSelectedGiftcard(null);
		setSelectedCategory(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
		setSelectedProduct(null);
		setSelectedPurchase(null);
		setSelectedUser(null);
		setSelectedGiftcard(null);
		setSelectedAliance(null);
		setSelectedUserCategory(null);
		setSelectedMedio(null)
	};
	const handleUserCategoryClick = (userCategory) => {
		setSelectedUserCategory(userCategory);
		setSelectedCategory(null);
		setSelectedProduct(null);
		setSelectedPurchase(null);
		setSelectedUser(null);
		setSelectedGiftcard(null);
		setSelectedAliance(null);
		setSelectedMedio(null)
	};
	const handleMedioPagoClick = (medioPago) => {
		setSelectedMedio(medioPago)
		setSelectedUserCategory(null);
		setSelectedCategory(null);
		setSelectedProduct(null);
		setSelectedPurchase(null);
		setSelectedUser(null);
		setSelectedGiftcard(null);
		setSelectedAliance(null);
	};

	//modales de creacion-edicion-eliminacion productos
	const handleOpenModalNew = () => {
		setShowModalNew(true);
	};

	const handleCloseModalNew = () => {
		setShowModalNew(false);
	};

	const handleOpenModalEdit = () => {
		setShowModalEdit(true);
	};

	const handleCloseModalEdit = () => {
		setSelectedProduct(null);
		setShowModalEdit(false);
	};

	const handleSaveData = async (formData) => {
		try {
			const id = selectedProduct !== null ? selectedProduct?._id : selectedProductoInterno?._id
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/productos/${id}`,
				formData,
				config
			);
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const eventString = selectedProduct !== null ? "actualizar-producto" : "actualizar-interno"
			const event = new Event(eventString);
			window.dispatchEvent(event);

			toast.success('Producto actualizado con éxito');
		} catch (error) {
			toast.error('Error al actualizar producto');
		}
	};
	//-----------------------------------------
	//logica para actualizacion de productos
	const [porcentaje, setPorcentaje] = React.useState('');
	const[marcaPrecio, setMarcaPrecio] = React.useState("");
	const[tagPrecio, setTagPrecio] = React.useState("")
	const [operacion, setOperacion] = React.useState('mas');
	const [showAjustarPrecios, setShowAjustarPrecios] = React.useState(false);

	const handleOpenAjustarPrecios = () => {
		setShowAjustarPrecios(true); // Agrega un estado local para controlar la visibilidad del formulario
	};

	const handleAjustarPrecios = async () => {
		if (!porcentaje || !operacion) {
			toast.error('Porcentaje y operación son campos requeridos');
			return;
		}

		try {
			await ajustarPrecios(porcentaje, marcaPrecio, tagPrecio, operacion);
			// Restablecer los valores por defecto y ocultar el formulario
			setPorcentaje('');
			setMarcaPrecio("");
			setTagPrecio("");
			setOperacion('mas');
			setShowAjustarPrecios(false);
		} catch (error) {
			toast.error('Error al ajustar los precios');
		}
	};
	//----------------------------------------

	//logica para actualizacion de puntos
	const [porcentajePuntos, setPorcentajePuntos] = React.useState('');
	const[marcaPuntos, setMarcaPuntos] = React.useState("");
	const[tagPuntos, setTagPuntos] = React.useState("")
	const [showAjustarPuntos, setShowAjustarPuntos] = React.useState(false);

	const handleOpenAjustarPuntos = () => {
		setShowAjustarPuntos(true); // Agrega un estado local para controlar la visibilidad del formulario
	};

	const handleAjustarPuntos = async () => {
		if (!porcentajePuntos) {
			toast.error('Porcentaje es un campo requerido');
			return;
		}

		try {
			await ajustarPuntos(porcentajePuntos, marcaPuntos, tagPuntos);
			// Restablecer los valores por defecto y ocultar el formulario
			setPorcentajePuntos('');
			setMarcaPuntos('');
			setTagPuntos("");
		} catch (error) {
			toast.error('Error al ajustar los precios');
		}
	};

	//modal de edicion y creacion de compras
	const handleOpenModalNewPurchase = () => {
		setShowModalNewPurchase(true);
	};

	const handleCloseModalNewPurchase = () => {
		setShowModalNewPurchase(false);
		setSelectedPurchase(null);
	};

	const handleOpenModalEditPurchase = () => {
		setShowModalEditPurchase(true);
	};

	const handleCloseModalEditPurchase = () => {
		setShowModalEditPurchase(false);
		setSelectedPurchase(null);
	};
	//--------------------------------------------

	//modales de creacion - edicion de alianzas
	const handleOpenModalNewAliance = () => {
		setShowModalNewAliance(true);
	};

	const handleCloseModalNewAliance = () => {
		setShowModalNewAliance(false);
		setSelectedAliance(null);
	};

	const handleOpenModalEditAliance = () => {
		setShowModalEditAliance(true);
	};

	const handleCloseModalEditAliance = () => {
		setShowModalEditAliance(false);
		setSelectedAliance(null);
	};

	const handleSaveDataAliance = async (formData) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/alianzas/${selectedAliance?._id}`,
				formData,
				config
			);
			// Disparar un evento personalizado 'actualizar-alianza' cuando se haya actualizado el producto
			const event = new Event('actualizar-alianza');
			window.dispatchEvent(event);

			toast.success('Alianza actualizada con éxito');
		} catch (error) {
			toast.error('Error al actualizar la alianza');
		}
	};
	//----------------------------------------------------
	//modales de edicion - creacion de categorias
	//modales de creacion - edicion de alianzas
	const handleOpenModalNewCategory = () => {
		setShowModalNewCategory(true);
	};

	const handleCloseModalNewCategory = () => {
		setShowModalNewCategory(false);
		setSelectedCategory(null);
	};

	const handleOpenModalEditCategory = () => {
		setShowModalEditCategory(true);
	};

	const handleCloseModalEditCategory = () => {
		setShowModalEditCategory(false);
		setSelectedCategory(null);
	};

	//modales de edicion - creacion de categorias
	//modales de creacion - edicion de alianzas
	const handleOpenModalNewMedio = () => {
		setShowModalNewMedio(true);
	};

	const handleCloseModalNewMedio = () => {
		setShowModalNewMedio(false);
		setSelectedMedio(null);
	};

	const handleOpenModalEditMedio = () => {
		setShowModalEditMedio(true);
	};

	const handleCloseModalEditMedio = () => {
		setShowModalEditMedio(false);
		setSelectedMedio(null);
	};
	//----------------------------------------------------
	//modales de edicion - creacion de categorias usuario
	//modales de creacion - edicion de categorias usuario
	const handleOpenModalNewUserCategory = () => {
		setShowModalNewUserCategory(true);
	};

	const handleCloseModalNewUserCategory = () => {
		setShowModalNewUserCategory(false);
		setSelectedUserCategory(null);
	};

	const handleOpenModalEditUserCategory = () => {
		setShowModalEditUserCategory(true);
	};

	const handleCloseModalEditUserCategory = () => {
		setShowModalEditUserCategory(false);
		setSelectedUserCategory(null);
	};

	//modales de creacion - edicion de GIFTCARDS
	const handleOpenModalNewGiftcard = () => {
		setShowModalNewGiftcard(true);
	};

	const handleCloseModalNewGiftcard = () => {
		setShowModalNewGiftcard(false);
		setSelectedGiftcard(null);
	};

	const handleOpenModalEditGiftcard = () => {
		setShowModalEditGiftcard(true);
	};

	const handleCloseModalEditGiftcard = () => {
		setShowModalEditGiftcard(false);
		setSelectedGiftcard(null);
	};

	//modales de creacion - edicion de CLIENTES
	const handleOpenModalNewClient = () => {
		setShowModalNewClient(true);
	};

	const handleCleanUsers = async () => {
		try {
			// Realiza una solicitud POST para cambiar el estado del modo de mantenimiento
			const {data}= await axios.post(
				`${process.env.REACT_APP_BACK_URL}/api/usuarios/limpiarusuarios`,
				{	},
				config
			);
			toast.success(data?.msg)
			
		} catch (error) {
			toast.error('No se pudieron aplicar los cambios');
			console.error('Error al cambiar el modo de mantenimiento:', error);
		}
	}

	const handleCloseModalNewClient = () => {
		setShowModalNewClient(false);
		setSelectedUser(null);
	};

	const handleOpenModalEditClient = () => {
		setShowModalEditClient(true);
		
	};

	const handleCloseModalEditClient = () => {
		setShowModalEditClient(false);
		setSelectedUser(null);
	};

	//Funciones para barras de búsqueda
	const handleSearchProduct = (e) => {
		e.preventDefault();
		setProductosDesde(0);
		setPaginaActual(1);
		setTerminoProducto(e.target.value);
	};

	const handleSearchProductoInterno = (e) => {
		e.preventDefault();
		// setProductosDesde(0);
		// setPaginaActual(1);
		setTerminoConsumoInterno(e.target.value);
	};

	const handleSearchServicio = (e) => {
		e.preventDefault();
		// setProductosDesde(0);
		// setPaginaActual(1);
		setTerminoServicio(e.target.value);
	};
	const handleSearchUser = (e) => {
		e.preventDefault();
		setUsuariosDesde(0);
		setPaginaActual(1);
		setTerminoUsuario(e.target.value);
	};
	const handleSearchPurchase = (e) => {
		e.preventDefault();
		setComprasDesde(0);
		setPaginaActual(1);
		setTerminoCompra(e.target.value);
	};

	const handleSearchWebPurchase = (e) => {
		e.preventDefault();
		setComprasWebDesde(0);
		setPaginaActual(1);
		setTerminoCompraWeb(e.target.value);
	};
	const handleSearchAliance = (e) => {
		e.preventDefault();
		setAlianzasDesde(0);
		setPaginaActual(1);
		setTerminoAlianza(e.target.value);
	};
	const handleSearchGiftcard = (e) => {
		e.preventDefault();
		setGiftcardDesde(0);
		setPaginaActual(1);
		setTerminoGiftcard(e.target.value);
	};
	const handleSearchMedio = (e) => {
		e.preventDefault();
		setMediosDesde(0);
		setPaginaActual(1);
		setTerminoMedio(e.target.value);
	};
	//-------------------------------------------------------
	//funcion para iniciar o finalizar el modo mantenimiento
	const handleToggleModoMantenimiento = async () => {
		try {
			// Realiza una solicitud PUT para cambiar el estado del modo de mantenimiento
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/modomantenimiento`,
				{
					maintenanceMode: !modoMantenimiento, // Invierte el estado actual
				},
				config
			);

			// Actualiza el estado local después de cambiar el modo de mantenimiento
			setModoMantenimiento(!modoMantenimiento);
		} catch (error) {
			toast.error('No se pudo iniciar el modo de edicion');
			console.error('Error al cambiar el modo de mantenimiento:', error);
		}
	};

	
	// useEffect de autenticación
	React.useEffect(() => {
		if (user && user.role && isNotAllowed) {
			window.location.replace('/');
		}
	}, []);
	//Use effect para manejar eventos de USUARIOS
	React.useEffect(() => {
		async function obtenerUsuarios() {
			setUsers(await getUsers(usuariosDesde));
			setLoader(false);
		}
		async function buscarUsuarios() {
			setUsers(await searchAllUsers(terminoUsuario, usuariosDesde));
			setLoader(false);
		}

		if (terminoUsuario === '') {
			obtenerUsuarios();
		} else {
			buscarUsuarios();
		}
		window.addEventListener('actualizar-cliente', obtenerUsuarios);
		return () => {
			window.removeEventListener('actualizar-cliente', obtenerUsuarios);
		};
	}, [usuariosDesde, terminoUsuario]);

	//Use effect para manejar eventos de GIFTCARDS
	React.useEffect(() => {
		async function obtenerGifcards() {
			setGiftcards(await getGiftcards(giftcardDesde));
			setLoader(false);
		}
		async function buscarGiftcards() {
			setGiftcards(await searchAllGiftcards(terminoGiftcard, giftcardDesde));
			setLoader(false);
		}

		if (terminoGiftcard === '') {
			obtenerGifcards();
		} else {
			buscarGiftcards();
		}
		window.addEventListener('actualizar-giftcard', obtenerGifcards);
		return () => {
			window.removeEventListener('actualizar-giftcard', obtenerGifcards);
		};
	}, [giftcardDesde, terminoGiftcard]);

	//Use effect para manejar eventos de ALIANZAS
	React.useEffect(() => {
		async function obtenerAlianzas() {
			setAlianzas(await getAliances(alianzasDesde));
			setLoader(false);
		}
		async function buscarAlianzas() {
			setAlianzas(await searchAllAliances(terminoAlianza, alianzasDesde));
			setLoader(false);
		}

		if (terminoAlianza === '') {
			obtenerAlianzas();
		} else {
			buscarAlianzas();
		}
		window.addEventListener('actualizar-alianza', obtenerAlianzas);
		return () => {
			window.removeEventListener('actualizar-alianza', obtenerAlianzas);
		};
	}, [alianzasDesde, terminoAlianza]);

	//Use effect para manejar eventos de COMPRAS
	React.useEffect(() => {
		async function obtenerCompras() {
			setPurchases(await getPurchases(comprasDesde));
			setLoader(false);
		}
		async function buscarCompras() {
			setPurchases(await searchAllPurchases(terminoCompra, comprasDesde));
			setLoader(false);
		}

		if (terminoCompra === '') {
			obtenerCompras();
		} else {
			buscarCompras();
		}
		window.addEventListener('actualizar-compra', obtenerCompras);
		return () => {
			window.removeEventListener('actualizar-compra', obtenerCompras);
		};
	}, [comprasDesde, terminoCompra]);

		//Use effect para manejar eventos de COMPRAS WEB
		React.useEffect(() => {
			async function obtenerComprasWeb() {
				setWebPurchases(await getWebPurchases(comprasWebDesde));
				setLoader(false);
			}
			async function buscarComprasWeb() {
				setWebPurchases(await searchAllWebPurchases(terminoCompraWeb, comprasWebDesde));
				setLoader(false);
			}
	
			if (terminoCompraWeb === '') {
				obtenerComprasWeb();
			} else {
				buscarComprasWeb();
			}
			window.addEventListener('actualizar-compra', obtenerComprasWeb);
			return () => {
				window.removeEventListener('actualizar-compra', obtenerComprasWeb);
			};
		}, [comprasWebDesde, terminoCompraWeb]);

	//Use effect para manejar eventos de PRODUCTOS
	React.useEffect(() => {
		toast.remove();
		async function obtenerProductos() {
			const consumoInterno = true
			setProducts(await getProducts(productosDesde, consumoInterno));
			setLoader(false);
		}
		async function buscarProductos() {
			setProducts(await searchAllProducts(terminoProducto, productosDesde, true));
			setLoader(false);
		}

		if (terminoProducto === '') {
			obtenerProductos();
		} else {
			buscarProductos();
		}
		window.addEventListener('actualizar-producto', obtenerProductos);
		return () => {
			window.removeEventListener('actualizar-producto', obtenerProductos);
		};
	}, [productosDesde, terminoProducto]);

		//Use effect para manejar eventos de PRODUCTOS CONSUMO INTERNO
		React.useEffect(() => {
			toast.remove();
			const idCategory = "6515922a5c9c1b286082fc75"
			async function obtenerProductosConsumoInterno() {
				setProductosConsumoInterno(await getProductosInternos(idCategory,5000));
				setLoader(false);
			}
			async function buscarProductos() {
				setProductosConsumoInterno(await searchProducts(idCategory, terminoConsumoInterno,0,5000,true,true));
				setLoader(false);
			}
	
			if (terminoConsumoInterno === '') {
				obtenerProductosConsumoInterno();
			} else {
				buscarProductos();
			}
			window.addEventListener('actualizar-interno', obtenerProductosConsumoInterno);
			return () => {
				window.removeEventListener('actualizar-interno', obtenerProductosConsumoInterno);
			};
		}, [terminoConsumoInterno]);

		// console.log("productosdesde",productosDesde)
		// console.log("productosporpagina",productosPorPagina)
		//Use effect para manejar eventos de SERVICIOS
		React.useEffect(() => {
			toast.remove();
			const idCategory = "66059c17f326b6106b50a37c"
			async function obtenerServicios() {
				setServicios(await getServicios(idCategory,5000));
				setLoader(false);
			}
			async function buscarServicios() {
				setServicios(await searchProducts(idCategory, terminoServicio,0,5000));
				setLoader(false);
			}
	
			if (terminoServicio === '') {
				obtenerServicios();
			} else {
				buscarServicios();
			}
			window.addEventListener('actualizar-interno', obtenerServicios);
			return () => {
				window.removeEventListener('actualizar-interno', obtenerServicios);
			};
		}, [terminoServicio]);

	//Use effect para manejar eventos de CATEGORIAS
	React.useEffect(() => {
		async function obtenerCategorias() {
			setCategorias(await getAllCategories(categoriasDesde));
			setLoader(false);
		}

		obtenerCategorias();

		window.addEventListener('actualizar-categoria', obtenerCategorias);
		return () => {
			window.removeEventListener('actualizar-categorias', obtenerCategorias);
		};
	}, [categoriasDesde]);

	//Use effect para manejar eventos de MEDIOS DE PAGO
	React.useEffect(() => {
		toast.remove();
		async function obtenerMedios() {
			setMediosPago(await getMediosPago(mediosDesde));
			setLoader(false);
		}
		async function buscarMedios() {
			setMediosPago(await searchMedios(terminoMedio, mediosDesde, true));
			setLoader(false);
		}

		if (terminoMedio === '') {
			obtenerMedios();
		} else {
			buscarMedios();
		}
		const actualizarMediosListener = () => {
			obtenerMedios();
		};
	
		window.addEventListener('actualizar-medio', actualizarMediosListener)
		return () => {
			window.removeEventListener('actualizar-medios', obtenerMedios);
		};
	}, [mediosDesde, terminoMedio]);

	//Use effect para manejar eventos de CATEGORIAS DE USUARIO
	React.useEffect(() => {
		async function obtenerCategoriasUsuario() {
			setCategoriasUsuarios(await getUserCategories(categoriasUsuariosDesde));
			setLoader(false);
		}

		obtenerCategoriasUsuario();

		window.addEventListener('actualizar-categoriaUsuario', obtenerCategoriasUsuario);
		return () => {
			window.removeEventListener('actualizar-categoriasUsuario', obtenerCategoriasUsuario);
		};
	}, [categoriasUsuariosDesde]);

	// useEffect y lógica para cambiar el fondo de pantalla del Dash
	React.useEffect(() => {
		const cargarImagen = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_DASHBOARD`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagen();
	}, [renderKey]);

	//useEffect para manejar el modo mantenimiento
	React.useEffect(() => {
		// Hacer una solicitud GET para obtener el estado del modo de mantenimiento
		const obtenerModoMantenimiento = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/modomantenimiento`);

				if (response.status === 200) {
					// Actualizar el estado local con el valor del modo de mantenimiento
					setModoMantenimiento(response.data.modoMantenimiento.maintenanceMode);
				}
			} catch (error) {
				console.error('Error al obtener el modo de mantenimiento:', error);
			}
		};

		// Llamar a la función para obtener el estado del modo de mantenimiento
		obtenerModoMantenimiento();
	}, []); // Asegúrate de que el array de dependencias esté vacío para que este efecto se ejecute solo una vez al montar el componente

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}
	return (
		<>
			{!image?.url ? (
				<Loader />
			) : (
				<div className='fondoDashBoard' style={{ backgroundImage: `url(${image?.url})` }}>
					<Box className="headerMarket" >
						<NavBar />
					</Box>

					<Box className='containerDashBoard'>
						{isSuperAdmin && (
							<div className='fondoPrevButton' onClick={() => handleOpenWidget()}>
								<i className='bi bi-pencil'></i>
								cambiar fondo
							</div>
						)}
						<Toaster
							position='bottom-left'
							reverseOrder={false}
							toastOptions={{ className: 'fondoToast' }}
						/>
						<h1>Panel de Administracion</h1>

						<button className='buttonCrearProducto mx-4 my-2' onClick={handleOpenModalNewPurchase}>
							Nueva venta/servicio
						</button>
						{isSuperAdmin && (
							<button
							style={{background:"red"}}
								className='buttonCrearProducto mx-4 my-2'
								onClick={handleToggleModoMantenimiento}
							>
								{modoMantenimiento ? 'DESACTIVAR mantenimiento' : 'ACTIVAR mantenimiento'}
							</button>
						)}
						{modoMantenimiento ? (
							<h4 style={{backgroundColor:"#20563f", color:"#ffff"}}>Modo mantenimiento <strong style={{color:"#ffff"}}>ACTIVADO</strong></h4>
						) : (
							<h4 style={{backgroundColor:"#20563f", color:"#ffff"}}>Modo mantenimiento <strong style={{color:"#ffff"}}>DESACTIVADO</strong></h4>
						)}
						{showModalNewPurchase && (
							<ModalNewPurchase
								show={showModalNewPurchase}
								handleClose={handleCloseModalNewPurchase}
							/>
						)}

						<Tabs defaultActiveKey='users' id='admin-dashboard-tabs' className='tabsAdmin'>
							<Tab eventKey='users' title='Clientes' className='tabAdmin'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoUsuario}
											onChange={(e) => handleSearchUser(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									<div style={{display:"flex"}}>
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleOpenModalNewClient}
									>
										Crear Usuario
									</button>
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleCleanUsers}
									>
										Limpiar usuarios inactivos
									</button>
									</div>
									
								</div>
								<UsersTable users={users.usuarios} onUserClick={handleUserClick} />
								<Pagination
									key={paginaActual}
									productosPorPagina={clientesPorPagina}
									productos={users.total}
									paginado={paginadoUsers}
									paginaActual={paginaActual}
								/>
								<ModalNewClient show={showModalNewClient} handleClose={handleCloseModalNewClient} />
								{selectedUser && (
									<div className='containerInfo mb-5' style={{display:"flex", flexDirection:"column"}}>
										<h2>Informacion del Usuario</h2>

										<div style={{display:"flex", flexDirection: isMobileScreen?"column":"row", justifyContent:"space-around", width:"100%"}}>
												<div>
												<p>
											ID: <span>{selectedUser._id}</span>
										</p>
										<p>
											Categoria: <span>{selectedUser?.categoria?.nombre?.toUpperCase()}</span>
											
										</p>
										<p>
											Estado de la cuenta:{' '}
											<span>{selectedUser?.estado ? 'Activo' : 'Inactivo'}</span>
										</p>
										<p>
											Perlas: <span>{selectedUser?.puntosAcumulados}</span>
										</p>

										<p>
											El usuario tiene estos beneficios disponibles:
										</p>
										<ul>
											{selectedUser.beneficios
												?.filter((beneficio) => beneficio.disponible === true) // Filtrar los beneficios disponibles
												.map((beneficio) => (
													<li key={beneficio._id}>{beneficio.nombre}</li>
												))}
										</ul>

											
										<p>
											Rol:{' '}
											<span>
												{selectedUser?.role === 'USER_ROLE' ? 'USUARIO' : selectedUser.role}
											</span>
										</p>
												</div>
												<div>
												<p>
											Nombre y apellido:{' '}
											<span>{`${selectedUser.nombre?.toUpperCase()} ${selectedUser.apellido?.toUpperCase()}`}</span>
										</p>

										<p>
											DNI: <span>{selectedUser?.dni ? selectedUser?.dni : ''}</span>
										</p>

												
										<p>
											Cumpleaños:{' '}
											<span>
												{selectedUser?.cumpleaños ? formatDate(selectedUser?.cumpleaños) : ''}
											</span>
										</p>

										<p>
											Notas:{' '}
											<span>{selectedUser?.notas ? selectedUser.notas : 'No hay notas.'}</span>
										</p>

										
										<p>
											Fecha de la última compra:{' '}
											{selectedUser?.fechaUltimaCompra ? (

												<span>{formatDate(selectedUser?.fechaUltimaCompra)}</span>
											):(
												<span>El usuario aún no realizó compras</span>
											)}
										</p>
										
												<button className='buttonCrearProducto' style={{width:"60%"}} onClick={handleOpenModalEditClient}>
											Editar Cliente
										</button>
												</div>
										</div>
										
										

										{/* {
											selectedUser?.correo && (
										<p>
											Email: <span>{selectedUser?.correo}</span>
										</p>

											)
										} */}
										
							
										
								
								
											
							
									
												

										
										{selectedUser.nombre && (
											<ModalEditClient
												show={showModalEditClient}
												handleClose={handleCloseModalEditClient}
												client={selectedUser}
											/>
										)}
									</div>
								)}
							</Tab>
		

							<Tab eventKey='products' title='Productos'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoProducto}
											onChange={(e) => handleSearchProduct(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									{isSuperAdmin && (
										<div style={{display:"flex"}}>
											<button
												className='buttonCrearProducto mx-4 my-2'
												onClick={handleOpenAjustarPrecios}
											>
												Ajustar Precios
											</button>
											{showAjustarPrecios && (
												<div className='ajustarPreciosForm'>
													<div className='ajustarInputs'>
													<input
														className='inputPorcentaje'
														type='number'
														placeholder='Porcentaje'
														value={porcentaje}
														onChange={(e) => setPorcentaje(e.target.value)}
													/>
													<input
														className='inputPorcentaje'
														type='text'
														placeholder='Marca'
														value={marcaPrecio}
														onChange={(e) => setMarcaPrecio(e.target.value)}
													/>
													<input
														className='inputPorcentaje'
														type='text'
														placeholder='Tag'
														value={tagPrecio}
														onChange={(e) => setTagPrecio(e.target.value)}
													/>
													<select value={operacion} onChange={(e) => setOperacion(e.target.value)}>
														<option value='mas'>Más</option>
														<option value='menos'>Menos</option>
													</select>
													</div>
												

													<button
														className='buttonCrearProducto mx-4 my-2 aplicarAjuste'
														onClick={handleAjustarPrecios}
													>
														Aplicar
													</button>
													<i
														className='bi bi-x-circle filterOptionX'
														onClick={() => {
															setShowAjustarPrecios(false); // Establecer tipoOrdenamiento en cadena vacía
														}}
													></i>
												</div>
											)}
											
											<button
												className='buttonCrearProducto mx-4 my-2'
												onClick={handleOpenAjustarPuntos}
											>
												Ajustar Perlas
											</button>
											{showAjustarPuntos && (
												<div className='ajustarPreciosForm'>
													<div className='ajustarInputs'>
													<input
														className='inputPorcentaje'
														type='number'
														placeholder='Porcentaje'
														value={porcentajePuntos}
														onChange={(e) => setPorcentajePuntos(e.target.value)}
													/>
													<input
														className='inputPorcentaje'
														type='text'
														placeholder='Marca'
														value={marcaPuntos}
														onChange={(e) => setMarcaPuntos(e.target.value)}
													/>
													<input
														className='inputPorcentaje'
														type='text'
														placeholder='Tag'
														value={tagPuntos}
														onChange={(e) => setTagPuntos(e.target.value)}
													/>
													</div>
													
													<button
														className='buttonCrearProducto mx-4 my-2 aplicarAjuste'
														onClick={handleAjustarPuntos}
													>
														Aplicar
													</button>
													<i
														className='bi bi-x-circle filterOptionX'
														onClick={() => {
															setShowAjustarPuntos(false); // Establecer tipoOrdenamiento en cadena vacía
														}}
													></i>
												</div>
											)}


											<button
												className='buttonCrearProducto mx-4 my-2'
												onClick={handleOpenModalNew}
											>
												Crear Producto
											</button>
										</div>
									)}
								</div>

								<ProductsTable products={products.productos} onProductClick={handleProductClick} />

								<Pagination
									key={paginaActual}
									productosPorPagina={productosPorPagina}
									productos={products.total}
									paginado={paginadoProducts}
									paginaActual={paginaActual}
								/>
								{showModalNew && (
									<ModalNewProduct show={showModalNew} handleClose={handleCloseModalNew} />
								)}
								{selectedProduct && (
									<div className='containerInfo mb-5'>
										<h2>Informacion del Producto</h2>
										<p>
											Nombre: <span>{selectedProduct.nombre}</span>
										</p>
										<p>
											Precio Promo: <span>{currencyDisplay(selectedProduct.precioEfectivo)}</span>
										</p>
										<p>
											Precio de Lista: <span>{currencyDisplay(selectedProduct.precioTarjeta)}</span>
										</p>
										{selectedProduct?.precioOferta > 0 && (
											<p>
												Precio de Oferta:{' '}
												<span>{currencyDisplay(selectedProduct.precioOferta)}</span>
											</p>
										)}

										<button className='buttonCrearProducto' onClick={handleOpenModalEdit}>
											editar producto
										</button>
										{selectedProduct && showModalEdit && (
											<ModalEditProduct
												show={showModalEdit}
												handleClose={handleCloseModalEdit}
												sale={selectedProduct}
												handleSave={handleSaveData}
											/>
										)}
									</div>
								)}
							</Tab>

							<Tab eventKey='services' title='Servicios'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoServicio}
											onChange={(e) => handleSearchServicio(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									{isSuperAdmin && (
											<button
												className='buttonCrearProducto mx-4 my-2'
												onClick={handleOpenModalNew}
											>
												Crear Producto
											</button>										
									)}
								</div>

								<ServiciosTable products={servicios.productos} onProductClick={handleProductClick} />

								{showModalNew && (
									<ModalNewProduct show={showModalNew} handleClose={handleCloseModalNew} />
								)}
								{selectedProduct && (
									<div className='containerInfo mb-5'>
										<h2>Informacion del Producto</h2>
										<p>
											Nombre: <span>{selectedProduct.nombre}</span>
										</p>
										<p>
											Precio Promo: <span>{currencyDisplay(selectedProduct.precioEfectivo)}</span>
										</p>
										<p>
											Precio de Lista: <span>{currencyDisplay(selectedProduct.precioTarjeta)}</span>
										</p>
										{selectedProduct?.precioOferta > 0 && (
											<p>
												Precio de Oferta:{' '}
												<span>{currencyDisplay(selectedProduct.precioOferta)}</span>
											</p>
										)}

										<button className='buttonCrearProducto' onClick={handleOpenModalEdit}>
											editar producto
										</button>
										{selectedProduct && showModalEdit && (
											<ModalEditProduct
												show={showModalEdit}
												handleClose={handleCloseModalEdit}
												sale={selectedProduct}
												handleSave={handleSaveData}
											/>
										)}
									</div>
								)}
							</Tab>

							<Tab eventKey='purchases' title='Ventas'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoCompra}
											onChange={(e) => handleSearchPurchase(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
								</div>
								<PurchasesTable
									purchases={purchases.compras}
									onPurchaseClick={handlePurchaseClick}
								/>
								<Pagination
									key={paginaActual}
									productosPorPagina={ventasPorPagina}
									productos={purchases.total}
									paginado={paginadoPurchases}
									paginaActual={paginaActual}
								/>
								{selectedPurchase && (
									<div className='containerInfo mb-5'>
										<h2>Informacion de la venta</h2>
										<div style={{display:"flex", flexDirection: isMobileScreen?"column":"row", justifyContent:"space-around", width:"100%"}}>
										<div>
										<p>
											Fecha: <span>{formatDate(selectedPurchase?.fecha)}</span>
										</p>
										<p>
										Atendido por:{' '}
											{
												selectedPurchase?.atendidoPor?.nombre ? (
													<span>{`${capitalizeWord(selectedPurchase?.atendidoPor?.nombre)} ${capitalizeWord(
														selectedPurchase?.atendidoPor?.apellido
													)||""}`}</span>
												):<span>Usuario no encontrado</span>
											}
										</p>
										<p>
											Cantidad de Productos: <span>{selectedPurchase?.detalle?.length}</span>
										</p>
										<p>
											Total de la venta:{' '}
											<span>
												<strong className='totalCompra'>
													{currencyDisplay(selectedPurchase?.total)}
												</strong>
											</span>
										</p>

										<p>Detalle de medios de pago:</p>
										{selectedPurchase?.mediosPago.length > 0 ? (
											<ul>
												{selectedPurchase?.mediosPago?.map((medio, index) => {
													return (
														<div key={index}>
															<li>
																{medio.nombre} -{' '}
																<strong className='totalCompra'>
																	{currencyDisplay(medio?.monto)}
																</strong>
															</li>
														</div>
													);
												})}
											</ul>
										) : (
											<span>Debes ingresar la venta y asignar los medios de pago</span>
										)}
										</div>
										<div>
										<p>
											Estado: <span>{selectedPurchase?.estadoCompra}</span>
										</p>
										<p>
											Cliente:{' '}
											{
												selectedPurchase?.cliente?.nombre ? (
													<span>{`${capitalizeWord(selectedPurchase?.cliente?.nombre)} ${capitalizeWord(
														selectedPurchase?.cliente?.apellido
													)||""}`}</span>
												):"Usuario no encontrado"
											}
										
										</p>

										<p>Detalle:</p>
										<ul>
											{selectedPurchase?.detalle?.map((producto, index) => {
												return (
													<div key={index}>
														<li>
															{producto.nombre} - ({producto.cantidad})-
															<strong className='totalCompra'>
																{currencyDisplay(producto.precioElegido)}
															</strong>
														</li>
													</div>
												);
											})}
										</ul>
										<p>
											Notas: <span>{selectedPurchase?.notas}</span>
										</p>
										<p>
											Perlas: <span>{selectedPurchase?.totalPuntos}</span>
										</p>
										{(isSuperAdmin || isAdmin) &&
											selectedPurchase?.estadoCompra === 'PENDIENTE' && (
												<button
													className='buttonCrearProducto'
													onClick={handleOpenModalEditPurchase}
												>
													Ingresar venta
												</button>
											)}

										{isSuperAdmin && selectedPurchase?.estadoCompra === 'FINALIZADA' && (
											<button className='buttonCrearProducto' onClick={handleOpenModalEditPurchase}>
												Editar venta
											</button>
										)}
										</div>
										</div>
										
								
										{selectedPurchase && showModalEditPurchase && (
											<ModalEditPurchase
												show={showModalEditPurchase}
												handleClose={handleCloseModalEditPurchase}
												purchase={selectedPurchase}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='purchasesWeb' title='Ventas Market'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoCompraWeb}
											onChange={(e) => handleSearchWebPurchase(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
								</div>
								<PurchasesWebTable
									purchases={webPurchases.compras}
									onPurchaseClick={handlePurchaseClick}
								/>
								<Pagination
									key={paginaActual}
									productosPorPagina={ventasMarketPorPagina}
									productos={webPurchases.total}
									paginado={paginadoWebPurchases}
									paginaActual={paginaActual}
								/>
								{selectedPurchase && (
									<div className='containerInfo mb-5'>
										<h2>Informacion de la venta</h2>
										<div style={{display:"flex", flexDirection: isMobileScreen?"column":"row", justifyContent:"space-around", width:"100%"}}>
										<div>
										<p>
											Fecha: <span>{formatDate(selectedPurchase?.fecha)}</span>
										</p>
										<p>
										Atendido por:{' '}
											{
												selectedPurchase?.atendidoPor?.nombre ? (
													<span>{`${capitalizeWord(selectedPurchase?.atendidoPor?.nombre)} ${capitalizeWord(
														selectedPurchase?.atendidoPor?.apellido
													)||""}`}</span>
												):<span>Usuario no encontrado</span>
											}
										</p>
										<p>
											Cantidad de Productos: <span>{selectedPurchase?.detalle?.length}</span>
										</p>
										<p>
											Total de la venta:{' '}
											<span>
												<strong className='totalCompra'>
													{currencyDisplay(selectedPurchase?.total)}
												</strong>
											</span>
										</p>

										<p>Detalle de medios de pago:</p>
										{selectedPurchase?.mediosPago.length > 0 ? (
											<ul>
												{selectedPurchase?.mediosPago?.map((medio, index) => {
													return (
														<div key={index}>
															<li>
																{medio.nombre} -{' '}
																<strong className='totalCompra'>
																	{currencyDisplay(medio?.monto)}
																</strong>
															</li>
														</div>
													);
												})}
											</ul>
										) : (
											<span>Debes ingresar la venta y asignar los medios de pago</span>
										)}
										</div>
										<div>
										<p>
											Estado: <span>{selectedPurchase?.estadoCompra}</span>
										</p>
										<p>
											Cliente:{' '}
											{
												selectedPurchase?.cliente?.nombre ? (
													<span>{`${capitalizeWord(selectedPurchase?.cliente?.nombre)} ${capitalizeWord(
														selectedPurchase?.cliente?.apellido
													)||""}`}</span>
												):"Usuario no encontrado"
											}
										
										</p>

										<p>Detalle:</p>
										<ul>
											{selectedPurchase?.detalle?.map((producto, index) => {
												return (
													<div key={index}>
														<li>
															{producto.nombre} - ({producto.cantidad})-
															<strong className='totalCompra'>
																{currencyDisplay(producto.precioElegido)}
															</strong>
														</li>
													</div>
												);
											})}
										</ul>
										<p>
											Notas: <span>{selectedPurchase?.notas}</span>
										</p>
										<p>
											Perlas: <span>{selectedPurchase?.totalPuntos}</span>
										</p>
										{(isSuperAdmin || isAdmin) &&
											selectedPurchase?.estadoCompra === 'PENDIENTE' && (
												<button
													className='buttonCrearProducto'
													onClick={handleOpenModalEditPurchase}
												>
													Ingresar venta
												</button>
											)}

										{isSuperAdmin && selectedPurchase?.estadoCompra === 'FINALIZADA' && (
											<button className='buttonCrearProducto' onClick={handleOpenModalEditPurchase}>
												Editar venta
											</button>
										)}
										</div>
										</div>
										{selectedPurchase && showModalEditPurchase && (
											<ModalEditPurchase
												show={showModalEditPurchase}
												handleClose={handleCloseModalEditPurchase}
												purchase={selectedPurchase}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='giftcards' title='Giftcards'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoGiftcard}
											onChange={(e) => handleSearchGiftcard(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleOpenModalNewGiftcard}
									>
										Nueva Giftcard
									</button>
								</div>
								<GiftcardsTable
									giftcards={giftCards?.giftcards}
									onGiftcardClick={handleGiftcardClick}
								/>
								<Pagination
									key={paginaActual}
									productosPorPagina={giftCardPorPagina}
									productos={giftCards.total}
									paginado={paginadoGiftcards}
									paginaActual={paginaActual}
								/>
								{showModalNewGiftcard && (
									<ModalNewGiftcard
										show={showModalNewGiftcard}
										handleClose={handleCloseModalNewGiftcard}
									/>
								)}
								{selectedGiftcard && (
									<div className='containerInfo mb-5'>
										<h2>Informacion de la Giftcard</h2>
										<p>
											Fecha: <span>{formatDate(selectedGiftcard.fecha)}</span>
										</p>
										<p>
											De: <span>{selectedGiftcard.de}</span>
										</p>
										<p>
											Para: <span>{selectedGiftcard.para}</span>
										</p>
									
										<p>
											Fecha de vencimiento: <span>{formatDate(selectedGiftcard.vencimiento)}</span>
										</p>
										<p>
											Diamantes: <span>{selectedGiftcard.precio}</span>
										</p>
										<p>
											Estado: <span>{selectedGiftcard.estado}</span>
										</p>
										<p>
											Código: <span>{selectedGiftcard.codigo}</span>
										</p>

										<p>
											Detalle de giftcard:
										</p>
										<ul>
											{selectedGiftcard.detalle
												?.map((item, index) => (
													<li key={index}>{item}</li>
												))}
										</ul>

										<button className='buttonCrearProducto' onClick={handleOpenModalEditGiftcard}>
											editar giftcard
										</button>
										{selectedGiftcard && showModalEditGiftcard && (
											<ModalEditGiftcard
												show={showModalEditGiftcard}
												handleClose={handleCloseModalEditGiftcard}
												giftcard={selectedGiftcard}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='aliances' title='Alianzas'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoAlianza}
											onChange={(e) => handleSearchAliance(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleOpenModalNewAliance}
									>
										Crear Nueva alianza
									</button>
								</div>
								<AliancesTable aliances={alianzas?.alianzas} onAlianceClick={handleAlianceClick} />
								<Pagination
									key={paginaActual}
									productosPorPagina={productosPorPagina}
									productos={alianzas.total}
									paginado={paginadoAliances}
									paginaActual={paginaActual}
								/>
								{showModalNewAliance && (
									<ModalNewAliance
										show={showModalNewAliance}
										handleClose={handleCloseModalNewAliance}
									/>
								)}
								{selectedAliance && (
									<div className='containerInfo mb-5'>
										<h2>Informacion de la Alianza</h2>
										<p>
											Nombre: <span>{selectedAliance.nombre}</span>
										</p>
										<p>
											Descripcion: <span>{selectedAliance.descripcion}</span>
										</p>

										<button className='buttonCrearProducto' onClick={handleOpenModalEditAliance}>
											editar alianza
										</button>
										{selectedAliance.nombre && showModalEditAliance && (
											<ModalEditAliance
												show={showModalEditAliance}
												handleClose={handleCloseModalEditAliance}
												aliance={selectedAliance}
												handleSave={handleSaveDataAliance}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='categories' title='Categorias'>
								<div className='containerTablaProductos'>
									{/* <form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoAlianza}
											onChange={(e) => handleSearchC(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form> */}
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleOpenModalNewCategory}
									>
										Crear Nueva categoria
									</button>
								</div>
								<CategoriesTable
									categories={categorias?.categorias}
									onCategoryClick={handleCategoryClick}
								/>
								{/* <Pagination
									key={paginaActual}
									productosPorPagina={productosPorPagina}
									productos={categorias.total}
									paginado={paginadoCategories}
									paginaActual={paginaActual}
								/> */}
								{showModalNewCategory && (
									<ModalNewCategory
										show={showModalNewCategory}
										handleClose={handleCloseModalNewCategory}
									/>
								)}
								{selectedCategory && (
									<div className='containerInfo mb-5'>
										<h2>Informacion de la Categoria</h2>
										<p>
											Nombre: <span>{selectedCategory.nombre}</span>
										</p>
										<p>
											Estado: <span>{selectedCategory.estado.toString()}</span>
										</p>

										<button className='buttonCrearProducto' onClick={handleOpenModalEditCategory}>
											editar categoria
										</button>
										{selectedCategory.nombre && showModalEditCategory && (
											<ModalEditCategory
												show={showModalEditCategory}
												handleClose={handleCloseModalEditCategory}
												category={selectedCategory}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='mediosPago' title='Medios de pago'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoMedio}
											onChange={(e) => handleSearchMedio(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleOpenModalNewMedio}
									>
										Crear nuevo medio de pago
									</button>
								</div>
								<MediosPagoTable
									mediosPago={mediosPago.mediosDePago}
									onMedioPagoClick={handleMedioPagoClick}
								/>
								<Pagination
									key={paginaActual}
									productosPorPagina={mediosPagoPorPagina}
									productos={mediosPago.total}
									paginado={paginadoMediosPago}
									paginaActual={paginaActual}
								/>
								{showModalNewMedio && (
									<ModalNewMedioPago
										show={showModalNewMedio}
										handleClose={handleCloseModalNewMedio}
									/>
								)}
								{selectedMedio && (
									<div className='containerInfo mb-5'>
										<h2>Informacion del Medio de Pago</h2>
										<p>
											Nombre: <span>{selectedMedio.nombre}</span>
										</p>
										<p>
											Estado: <span>{selectedMedio?.estado?.toString()}</span>
										</p>

										<button className='buttonCrearProducto' onClick={handleOpenModalEditMedio}>
											editar medio de pago
										</button>
										{selectedMedio.nombre && showModalEditMedio && (
											<ModalEditMedioPago
												show={showModalEditMedio}
												handleClose={handleCloseModalEditMedio}
												medio={selectedMedio}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='userCategories' title='Categorias Usuario'>
								<div className='containerTablaProductos' style={{flexDirection:"row-reverse"}}>
									<button
										className='buttonCrearProducto mx-4 my-2'
										onClick={handleOpenModalNewUserCategory}
									>
										Crear Nueva categoria de usuario
									</button>
								</div>
								<UserCategoriesTable
									userCategories={categoriasUsuarios?.categorias}
									onUserCategoryClick={handleUserCategoryClick}
								/>
								<Pagination
									key={paginaActual}
									productosPorPagina={categoriaUsuarioPorPagina}
									productos={categoriasUsuarios?.total}
									paginado={paginadoUserCategories}
									paginaActual={paginaActual}
								/>
								{showModalNewUserCategory && (
									<ModalNewUserCategory
										show={showModalNewUserCategory}
										handleClose={handleCloseModalNewUserCategory}
									/>
								)}
								{selectedUserCategory && (
									<div className='containerInfo mb-5'>
										<h2>Informacion de la Categoria</h2>
										<p>
											Nombre: <span>{selectedUserCategory?.nombre}</span>
										</p>
										<p>
											Puntos necesarios: <span>{selectedUserCategory?.puntosNecesarios}</span>
										</p>

										<button
											className='buttonCrearProducto'
											onClick={handleOpenModalEditUserCategory}
										>
											editar categoria usuario
										</button>
										{selectedUserCategory.nombre && showModalEditUserCategory && (
											<ModalEditUserCategory
												show={showModalEditUserCategory}
												handleClose={handleCloseModalEditUserCategory}
												userCategory={selectedUserCategory}
											/>
										)}
									</div>
								)}
							</Tab>
							<Tab eventKey='productosInternos' title='Consumo interno'>
								<div className='containerTablaProductos'>
									<form className='searchDashboard form-group has-feedback mx-4 my-2' role='search'>
										<input
											className='form-control inputSearch'
											type='search'
											placeholder='Buscar'
											value={terminoConsumoInterno}
											onChange={(e) => handleSearchProductoInterno(e)}
										/>
										<i className='bx bx-search form-control-feedback'></i>
									</form>
									{isSuperAdmin && (
										<div style={{display:"flex"}}>										
											<button
												className='buttonCrearProducto mx-4 my-2'
												onClick={handleOpenModalNew}
											>
												Crear Producto
											</button>
										</div>
									)}
								</div>

								<StockTable products={productosConsumoInterno.productos} onProductClick={handleProductoInternoClick} />
								{showModalNew && (
									<ModalNewProduct show={showModalNew} handleClose={handleCloseModalNew} />
								)}
								{selectedProductoInterno && (
									<div className='containerInfo mb-5'>
										<h2>Informacion del Producto</h2>
										<p>
											Nombre: <span>{selectedProductoInterno?.nombre}</span>
										</p>
										<p>
											Marca: <span>{selectedProductoInterno?.marca}</span>
										</p>
									
										

										<button className='buttonCrearProducto' onClick={handleOpenModalEdit}>
											editar producto
										</button>
										{selectedProductoInterno && showModalEdit && (
											<ModalEditProduct
												show={showModalEdit}
												handleClose={handleCloseModalEdit}
												sale={selectedProductoInterno}
												handleSave={handleSaveData}
											/>
										)}
									</div>
								)}
							</Tab>
						</Tabs>
					</Box>

					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}
