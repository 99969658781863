import axios from 'axios';
import { login, searchUser, setUserInfo, allUsers, clearInfo } from './userSlice';
//import PersonalizedToast from '../../components/toasts/Toast';
import toast from 'react-hot-toast';

const uri = process.env.REACT_APP_BACK_URL || 'http://localhost:8080';

/**
 * Funcion para loguear al usuario y setear en el estado global los datos correspondientes
 * @param {*Object} user Objeto con email y contraseña
 * Setea el estado global y la info en local storage
 */
export const loginFunction = (user, navigate) => async (dispatch) => {
	try {
		const { data } = await axios.post(`${uri}/api/auth/login`, user);

		const userQuery = await axios.get(`${uri}/api/usuarios/${data.usuario._id}`);
		localStorage.setItem('Usuario', data.usuario._id);
		localStorage.setItem('Token', data.token);
		dispatch(login(data.usuario._id));
		dispatch(setUserInfo(userQuery.data));
		data.mensajeCambioCategoria &&
			localStorage.setItem('cambioCategoria', data.mensajeCambioCategoria);

		toast.success(`Bienvenido ${data.usuario.nombre}`, { duration: 3000 });
		setTimeout(() => {
			// window.location.replace('/');
			navigate("/markethome")
		}, 500);
	} catch (error) {
		toast.error(`${error.response.data.msg}`, { duration: 3000 });
	}
};

/**
 * Funcion para traer la info de un usuario en particular por su id
 * @param {*String} id id del usuario a consultar
 * @setea la data en el estado global
 */
export const getUserById = (id) => async (dispatch) => {
	try {
		const { data } = await axios.get(`${uri}/api/usuarios/${id}`);
		dispatch(setUserInfo(data));
	} catch (error) {
		console.log(`Internal server error`);
	}
};

/**
 * Función para traer todos los usuarios con su información para que el SUPERADMIN la vea
 * @param {*} nada
 * @setea en el estado global a todos los usuarios.
 */
export const getUsers = () => async (dispatch) => {
	try {
		const { data } = await axios.get(`${uri}/api/usuarios/`);
		dispatch(allUsers(data));
	} catch (error) {
		console.log(`internal server error`);
	}
};

/**
 * Función que sirve para realizar búsquedas de usuarios
 * @param {*String} coleccion En este caso recibe la coleccion con el nombre de "usuarios"
 * @param {*String} termino Es lo que ingresa el usuario en la barra de búsqueda.
 * @setea en el estado global, el arreglo de usuarios que coinciden con la búsqueda
 */
export const searchUsers = (coleccion, termino) => async (dispatch) => {
	try {
		const { data } = await axios.get(`${uri}/api/buscar/${coleccion}/${termino}`);
		dispatch(searchUser(data.usuarios));
	} catch (error) {
		console.log(`Internal server error`);
	}
};

/**
 * Función que sirve para limpiar la infomación del usuario, su token y su id cuando cierra sesión
 * @params
 * @returns
 */
export const clearUserInfo = () => (dispatch) => {
	dispatch(clearInfo());
};
