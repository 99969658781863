// Importar las librerías necesarias
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import toast from 'react-hot-toast';

import axios from 'axios';

// Definir el componente EditModal
const ModalEditGanga = ({ show, handleClose, data }) => {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	// Manejar el estado del formulario en el modal
	const [formData, setFormData] = React.useState({
		titulo: data?.titulo,
		bajada: data?.bajada,
		url: data?.url || "",
		urlMobile:data?.urlMobile || "",
		colorTexto:data?.colorTexto,
		colorBoton:data?.colorBoton,
		link:data?.link
	});

// Manejar cambios en los campos del formulario
const handleChange = (e) => {
	const { name, value } = e.target;

	setFormData((prevData) => ({
			...prevData,
			[name]: value,
	}));
};


	// Manejar cambios en las imágenes (por ejemplo, usando el widget de Cloudinary)
	function handleOpenWidget(img) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;
					
						setFormData((prevData) => ({
							...prevData,
							url: url,
						}));
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			toast.error('Error al cargar la imagen', error);
		}
	}
	function handleOpenWidgetMobile(img) {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;
					
						setFormData((prevData) => ({
							...prevData,
							urlMobile: url,
						}));
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			console.log(error);
			toast.error('Error al cargar la imagen', error);
		}
	}

	const handleSaveData = async (formData) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes/${data?._id}`,
				formData,
				config
			);
			// Disparar un evento personalizado 'actualizar-alianza' cuando se haya actualizado el producto
			const event = new Event('actualizar-producto');
			window.dispatchEvent(event);

			toast.success('Data actualizada con éxito');
			setFormData({
				titulo: '',
				url:"",
				urlMobile:"",
				link:"",
				colorBoton:"",
				colorTexto:"",
				bajada:""
			});
			handleClose();
		} catch (error) {
			toast.error('Error al actualizar la data');
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Editar Seccion</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				{/* Agregar elementos de formulario para cada campo que deseas editar */}

				<div>
					<label htmlFor='nombre'>Titulo:</label>

					<input
						className='form-control'
						type='text'
						name='titulo'
						value={formData?.titulo}
						onChange={handleChange}
						placeholder='Titulo'
					/>
				</div>
				<div>
					<label htmlFor='nombre'>Subtitulo:</label>

					<input
						className='form-control'
						type='text'
						name='bajada'
						value={formData?.bajada}
						onChange={handleChange}
						placeholder='Subtitulo'
					/>
				</div>
				<div>
					<label htmlFor='nombre'>Link:</label>

					<input
						className='form-control'
						type='text'
						name='link'
						value={formData?.link}
						onChange={handleChange}
						placeholder='Link del boton'
					/>
				</div>
				<div>
    <label htmlFor='colorTexto'>Color del texto:</label>
    <input
        className='form-control'
        type='color'
        name='colorTexto'
        value={formData?.colorTexto}
        onChange={handleChange}
    />
</div>
<div>
    <label htmlFor='colorBoton'>Color del botón:</label>
    <input
        className='form-control'
        type='color'
        name='colorBoton'
        value={formData?.colorBoton}
        onChange={handleChange}
    />
</div>
	
				{/* Imagen 1 actual */}
				<section className='imgProductContainer'>
					<div className='imgEdit'>
						<img
				
							className='imgEditTag'
							src={formData?.url}
							alt='Imagen actual'
							style={{ objectFit:"cover",width:"90%", height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidget('img')}>
							Actualizar Imagen
						</Button>
					</div>
				</section>
				<section className='imgProductContainer'>
					<div className='imgEdit'>
						<img
				
							className='imgEditTag'
							src={formData?.urlMobile}
							alt='Imagen actual'
							style={{ objectFit:"cover",width:"90%", height: '150px', border: '1px solid black' }}
						/>
						<Button variant='primary' onClick={() => handleOpenWidgetMobile('img')}>
							Actualizar Imagen
						</Button>
					</div>
				</section>
				<div className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleSaveData(formData)}
					>
						Guardar Cambios
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditGanga;
