import React from 'react';
import Card from '../../components/card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import Pagination from '../../components/pagination/Pagination';
import { toast, Toaster } from 'react-hot-toast';
import { getSales } from '../../utils/products/products';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './Sale.Module.css';
import WhatsappButton from '../../components/whatsappButton/WhatsappButton';


function Sale() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const [image, setImage] = React.useState('');
	const [loader, setLoader] = React.useState(true);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);
	const navigate = useNavigate();
	//Paginado
	const [sales, setSales] = React.useState([]);

	const [paginaActual, setPaginaActual] = React.useState(1);
	const productosPorPagina = 8;
	const indiceUltimoProducto = paginaActual * productosPorPagina;
	const indicePrimerProducto = indiceUltimoProducto - productosPorPagina;
	const productosEnPantalla = sales.productos?.slice(indicePrimerProducto, indiceUltimoProducto);
	const paginado = (numeroPagina) => {
		setPaginaActual(numeroPagina);
	};

	React.useEffect(() => {
		toast.remove();
		async function getOfertas() {
			setSales(await getSales());
			setLoader(false);
		}
		getOfertas();
	}, []);

	React.useEffect(() => {
		const cargarImagen = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_OFERTAS`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagen();
	}, [renderKey]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}

	return (
		<div className='fondoSale' style={{ backgroundImage: `` }}>
			<header style={{position:"fixed", zIndex:100000}}>
				<NavBar />
			</header>
			<main className='mainSales'>
			<button className='fondoPrevButton buttonCata' onClick={()=>navigate(-1)}>
							<ChevronLeftIcon style={{ fill: "white" }} />
							Back
						</button>
			
				{loader ? (
					<Loader />
				) : (
					<>
						<Toaster
							position='bottom-left'
							reverseOrder={false}
							toastOptions={{ className: 'fondoToast' }}
						/>
						<h2 className='container tituloCatalogue'> </h2>

						<div className='container containerCatalogueSale'>
							{!productosEnPantalla?.length ? (
								<div className='divCatalogue px-2 py-2'>
									<h2>No hay productos en oferta...</h2>
								</div>
							) : (
								productosEnPantalla?.length &&
								productosEnPantalla?.map((producto, index) => {
									return (
										<div className='divCatalogue px-2 py-2' key={index}>
											<Card sale={producto} isMobile={isMobileScreen}/>
										</div>
									);
								})
							)}
						</div>
						<Pagination
							key={paginaActual}
							productosPorPagina={productosPorPagina}
							productos={sales.total}
							paginado={paginado}
							paginaActual={paginaActual}
						/>
						<WhatsappButton/>
					</>
				)}
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	);
}

export default Sale;
