import React from 'react';
import './WhatsappButton.Module.css';
import botonContacto from '../../assets/images/wpp2.png';

function WhatsappButton({ posicion }) {
	const whatsappLink = "https://api.whatsapp.com/send?phone=+5493415140700&text=Quiero%20info";

	return (
		<a
			href={whatsappLink}
			target="_blank"
			rel="noopener noreferrer"
			className={`whatsapp-button ${posicion === 'derechaButton' ? 'derechaButton' : 'fixedGloboWhat'}`}
		>
			<img src={botonContacto} alt='Botón WhatsApp' className='imgBotonGloboWhat' />
		</a>
	);
}

export default WhatsappButton;
