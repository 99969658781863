import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import './GangaSection.Module.css';
import Card from '../card/Card';
import { useMediaQuery } from 'react-responsive';
import gangas from "../../assets/images/gangas3.png"
import { Box, Typography, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalEditGanga from '../modals/modalEditGanga/ModalEditGanga';
import { darken } from '@mui/system'; // Importa darken
const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};
// Función para procesar la cadena


function GangaSection({ sales }) {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [dataSection, setDataSection] = React.useState(null);

	const [showModalEditData, setShowModalEditData] = React.useState(false);

	const handleOpenModalEditCategory = () => {
		setShowModalEditData(true);
	};

	const handleCloseModalEditCategory = () => {
		setShowModalEditData(false);


	};


	const navigate= useNavigate()
	const isMobile = useMediaQuery({ maxWidth: 700 });


const color=dataSection?.colorTexto

React.useEffect(() => {
	const cargarVideo = async () => {
		const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=GANGA_HOME`);
		setDataSection(data.imagenes[0]);
	};
	cargarVideo();
	window.addEventListener('actualizar-producto', cargarVideo);
}, []);


	return (
		<div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} className='container py-5'>
			   <img style={{width:"100VW", marginBottom:""}} src={isMobile? dataSection?.urlMobile : dataSection?.url} alt='gangaslogo'/>
			   <Typography style={{color:color,fontFamily:"Poppins", marginBottom:"5vh",marginTop:"5vh",fontSize: isMobile?"14px":"18px"}}>{dataSection?.titulo}</Typography>
			   <Grid container spacing={2} justifyContent="center">
                {sales?.productos?.map((sale, index) => (
                    <Grid item xs={6} md={3} key={index}>
                        <Card sale={sale} isMobile={isMobile} color={color}/>
                    </Grid>
                ))}
            </Grid>
			<Box 
																	
																	onClick={() => navigate(dataSection?.link)}  
																	sx={{	
																			marginTop:"5vh",
																				width:isMobile?"150px":"250px", 
																				height:"45px", 
																				backgroundColor:color ? color : "#E9CED2", 
																				display:"flex", 
																				justifyContent:"center", 
																				alignItems:"center",
																				cursor:"pointer",
													
																				transition: "all 0.3s ease",
																				boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
																
																				"&:hover": {
																backgroundColor: color ? darken(color, 0.2) : "#red",
																boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
															},
																
																				"&:active": {
																						transform: "scale(0.95)",
																				}
																				}}>
																	<Typography className='goBeautiGangas' sx={{color:color?"white":"#5c5c5c", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
																	{dataSection?.bajada}
																	</Typography>
																	
																</Box>
																{isSuperAdmin && (
							<button 
							className='edit-icon-sales' 
							onClick={() => (handleOpenModalEditCategory())}
							>
								<i className='bi bi-pencil'></i>
							</button>
						)}

							{dataSection?.url && showModalEditData && (
											<ModalEditGanga
												show={showModalEditData}
												handleClose={handleCloseModalEditCategory}
												data={dataSection}
											/>
										)}
		</div>
	);
}

export default GangaSection;
