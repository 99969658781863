import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import './CarouselOfertas.Module.css';
import Card from '../card/Card';
import { useMediaQuery } from 'react-responsive';
import gangas from "../../assets/images/gangas3.png"
import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalEditGanga from '../modals/modalEditGanga/ModalEditGanga';
import { darken } from '@mui/system'; // Importa darken
const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};
// Función para procesar la cadena


function CarouselOfertas({ sales }) {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [dataSection, setDataSection] = React.useState(null);

	const [showModalEditData, setShowModalEditData] = React.useState(false);

	const handleOpenModalEditCategory = () => {
		setShowModalEditData(true);
	};

	const handleCloseModalEditCategory = () => {
		setShowModalEditData(false);


	};


	const navigate= useNavigate()
	const isMobile = useMediaQuery({ maxWidth: 700 });




	React.useEffect(() => {
		const cargarVideo = async () => {
			const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=GANGA_SECTION`);
			setDataSection(data.imagenes[0]);
		};
		cargarVideo();
		window.addEventListener('actualizar-producto', cargarVideo);
	}, []);


	return (
		<div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} className='container py-5'>
			<Box>
				<h2 style={{marginTop:isMobile ? "-5vh":"15vh",	color:dataSection?.colorTexto,	fontFamily:"Poppins",}} className='py-5 tituloOfertas'>{dataSection?.titulo} </h2>
				<img style={{width:isMobile?"80vw":"550px", marginBottom:"", marginTop:"-5vh"}} src={dataSection?.url} alt='gangaslogo'/>
				<Typography style={{
														marginTop:isMobile ? "25px":"45px",
														marginBottom:"5vh",
														fontFamily:"Poppins",
														fontSize:"20px",
														fontWeight:400,
														color:dataSection?.colorTexto
														}} 
														className=''>{dataSection?.bajada} </Typography>
			</Box>
			<Carousel
				draggable={true}
				// showDots={true}
				// swipeable={true}
				swipeable={!isMobile} // Deshabilita swipeable en dispositivos móviles
				responsive={responsive}
				infinite={true}
				autoPlay={true}
				keyBoardControl={true}
				//autoPlay={props.deviceType !== 'mobile' ? true : false}
				autoPlaySpeed={5000}
				containerClass='carouselOfertas-container py-2'
				removeArrowOnDeviceType={['tablet']}

				//className='testimoniosCarousel'
			>
				{sales &&
					sales.productos?.map((sale, index) => {
						return (
							<div className='itemOfertaHome' key={index}>
								<Card sale={sale}  isMobile={isMobile}/>
							</div>
						);
					})}
			</Carousel>
			<Box 
				onClick={() => navigate("/ofertas")}  
				sx={{	
							marginTop:"5vh",
							width:isMobile?"150px":"250px", 
							height:"45px", 
							backgroundColor:dataSection?.colorBoton, 
							display:"flex", 
							justifyContent:"center", 
							alignItems:"center",
							cursor:"pointer",

							transition: "all 0.3s ease",
							boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
			
							"&:hover": {
            backgroundColor: dataSection?.colorBoton ? darken(dataSection.colorBoton, 0.2) : "#aa221e",
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
        },
			
							"&:active": {
									transform: "scale(0.95)",
							}
							}}>
				<Typography className='goBeautiGangas' sx={{color:"white", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
					Ir a BeautiGangas
				</Typography>
	
			</Box>
			{isSuperAdmin && (
							<button 
							className='edit-icon-sales' 
							onClick={() => (handleOpenModalEditCategory())}
							>
								<i className='bi bi-pencil'></i>
							</button>
						)}

							{dataSection?.url && showModalEditData && (
											<ModalEditGanga
												show={showModalEditData}
												handleClose={handleCloseModalEditCategory}
												data={dataSection}
											/>
										)}
		</div>
	);
}

export default CarouselOfertas;
