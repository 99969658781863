import React from "react";

import { Modal, Button } from "react-bootstrap";
import {  Typography, Stack, Box, Backdrop, CircularProgress, BackdropRoot, Paper, Grid, Dialog, DialogContent } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import bolsita from "../../assets/BOLSA_CARRITO.png";
import bolsaVacia from "../../assets/BOLSA_VACIA_OSCURA.png";
import { isAuthenticated } from "../../utils/auth/auth";
import ItemCart2 from "../../components/itemCart2/ItemCart2";
import { obtenerCostoProductos, obtenerPuntosProductos } from "../../utils/products/products";
import { currencyDisplay } from "../../utils/cart/cart";
import { getSales } from "../../utils/products/products";
import CarouselOfertasCart from "../../components/carousel/CarouselOfertasCart";
import { borrarCarrito } from "../../features-slice/cart/carritoSlice";
import ModalConfirmation from "../../components/modals/modalConfirmation/ModalConfirmation";
import toast from "react-hot-toast";
import { sendNewBuy } from "../../utils/cart/cart";
import { useMediaQuery } from 'react-responsive';
import PaymentButton from "../../components/paymentButton/PaymentButton";
import { getBuytById } from "../../utils/buys/buys";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";





export default function Success() {
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("payment_id");
  const externalReference = searchParams.get("external_reference");

  const navigate = useNavigate();

  const carrito = useSelector((state) => state.carrito);

    const [dataCompra, setDataCompra] = React.useState(null)

    const [costoEfect, setCostoEfect] = React.useState(0);
    const [costoTarjeta, setCostoTarjeta] = React.useState(0)
    const user = useSelector((state) => state.user.userInfo);
    const isMobile = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
    const [showTerms, setShowTerms] = React.useState(false)
    const handleShowTerms = () => {setShowTerms(true)}
    const handleShowTermsClose = () => {setShowTerms(false)}
    const cantidadPuntos = obtenerPuntosProductos(carrito);
    const [showSales, setShowSales] = React.useState(true);
    const [purchaseId, setPurchaseId] = React.useState("");
    const dispatch = useDispatch();
    //const navigate = useNavigate();

    React.useEffect(() =>  {
      if (externalReference) {
        const obtenerCompra = async () => {
          try {
            const dataPur = await getBuytById(externalReference);
            setDataCompra(dataPur);
          } catch (error) {
            toast.error('hubo un error obteniendo la compra');
          }
        };
    
        obtenerCompra();
      }
       
    }, [externalReference, paymentId]);

         React.useEffect(() => {
      
      }, [externalReference]);



  const [formData, setFormData] = React.useState({
    total:costoTarjeta,
    estadoCompra:"FINALIZADA",
    metodoPago:"MERCADO_PAGO",
    idPago:paymentId,
    mediosPago:[{medioPago:"67e97074c2f4e6d5bbee93e3", monto:costoTarjeta}],
    atendidoPor:"668003036088d4bb1108d5e5"
    });

    const handleEfectivo = () => {
      setFormData((prevData) => ({
        ...prevData,
        total: costoEfect,
        metodoPago:"EFECTIVO"
      }));
    };

    const handleTarjeta = () => {
      setFormData((prevData) => ({
        ...prevData,
        total: costoEfect,
        metodoPago:"TARJETA"
      }));
    };

    const handleClearForm = () => {
      setFormData((prevData) => ({
        ...prevData,
        total: 0,
        metodoPago:""
      }));
    };

  
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: token,
		},
	};
  const handleEditPurchase = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/compras/${dataCompra?._id}`,
        formData,
        config
      );
      const event = new Event('actualizar-compra');
      window.dispatchEvent(event);
      toast.success('Compra ingresada/actualizada con éxito');
      setFormData({});
  
      // Delay the navigation by 2 seconds
      setTimeout(() => {
        navigate("/markethome");
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error('Error al editar compra');
    }
  };
  



      // React.useEffect(() => {
      //   const obtenerCompra = async () => {
      //     try {
          
      //       const dataPur = await getBuytById(idCompra);
      //       setDataCompra(dataPur);
      //     } catch (error) {
      //       toast.error('hubo un error obteniendo la compra');
      //     }
      //   };
    
      //   obtenerCompra();
      // }, [idCompra]);

    // React.useEffect(() => {
    //   // Llama a la función obtenerCostoProductos con el carrito y el método de pago seleccionado
    //   const costo = obtenerCostoProductos(dataCompra?.detalle, "EFECTIVO");
    //   setCostoEfect(costo);
    // }, [idCompra, dataCompra]);
    React.useEffect(() => {
      // Calcula el costo basado en el detalle de la compra y el método de pago
      const costo = obtenerCostoProductos(dataCompra?.detalle, "TARJETA");
      
      setCostoTarjeta(costo);
    
      // Actualiza formData con el nuevo costo
      setFormData(prevFormData => ({
        ...prevFormData,
        total: costo,
        mediosPago: [{ ...prevFormData.mediosPago[0], monto: costo }]
      }));
    }, [externalReference, dataCompra]);
    

    // console.log("data de la compra", dataCompra)


    console.log("formdata", formData)
  
  return (
    <div style={{minWidth:"100vw", minHeight:"100vh", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
       <Typography sx={{
                              fontFamily:"Poppins",
                              color:"black",
                              fontSize:"18px",
                              fontWeight:400
                            }}>Tu pago se recibió con éxito</Typography>
            <Box 
                                sx={{
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center", 
                                    height:"48px",
                                    width:"90%", 
                                    backgroundColor:"#23563F",
                                    // borderRadius:"5px"
                                    }}
                                    onClick={() =>handleEditPurchase()}    
                                    
                                    >
                            <Typography sx={{
                              fontFamily:"Poppins",
                              color:"#ffff",
                              fontSize:"14px",
                              fontWeight:400
                            }}>Continuar</Typography>
                          </Box>
                          
              
      
    </div>
  )
}
