import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import './Promotion2.Module.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Typography, Stack, Box, Backdrop, CircularProgress, BackdropRoot, Paper, Grid } from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import ModalEditGanga from '../modals/modalEditGanga/ModalEditGanga';

function Promotion2() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const navigate = useNavigate()

	const urlCatalog  = `/market/categoria/64ef4986d269731b208134bf?nombre=MARKET`
	
		const [showModalEditData, setShowModalEditData] = React.useState(false);
	
		const handleOpenModalEditCategory = () => {
			setShowModalEditData(true);
		};
	
		const handleCloseModalEditCategory = () => {
			setShowModalEditData(false);
	
	
		};

	React.useEffect(() => {

		
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=HOME_SHOP`
			);
			setImage(data.imagenes);
			setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image[0]._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}
	return (
		<Box sx={{ position: "relative", zIndex:1 }}>
			{image && (
				<Box  
				onClick={() => {
					if (!isSuperAdmin) {
						navigate(image[0]?.link);
					}
				}}
				 className='containerPromotion2' sx={{cursor:"pointer", position: 'relative', display: 'flex', justifyContent: 'center' }}>
					<img src={image[0]?.url} alt='Imagen de promociones' />
					{isSuperAdmin && (
						<div className='edit-icon-promotion2' onClick={() => (handleOpenModalEditCategory())}
						>
							<i className='bi bi-pencil'></i>
						</div>
					)}
					{/* <Box 
					className="buttoncito"
					onClick={()=>navigate(urlCatalog)}
					// style={{backgroundColor:"#20563f", width:!isMobile?"30%":"100%", height:"60px",fontWeight:600, fontFamily:"Montserrat"}}
					sx={{ 
						display:"flex",
						alignItems:"center",
						justifyContent:"center",
						position: "absolute", 
						width:!isMobile?"30%":"100%",
						height: "60px", 
						borderRadius:"5px",
						backgroundColor: "#20563f", 
						bottom: "40%", 
						left: "50%", 
						cursor:"pointer",
						transform: "translateX(-50%)" 
					}}
					>
						<Typography sx={{fontFamily:"Montserrat",color:"#fff"}}>
					Ver catálogo completo
						</Typography>
					</Box> */}
							{image[0]?.url && showModalEditData && (
											<ModalEditGanga
												show={showModalEditData}
												handleClose={handleCloseModalEditCategory}
												data={image[0]}
											/>
										)}
				</Box>
			)}
		</Box>
	);
}

export default Promotion2;
