import React from 'react';
import Stamp from './Stamp';
import beautiComunidad from '../../assets/Sellos/BEAUTY_COMUNIDAD.png';
import beautiBox from '../../assets/Sellos/BEAUTY_BOX.png';
import giftCards from '../../assets/Sellos/GIFTCARDS.png';
import alianzas from '../../assets/Sellos/ALIANZAS.png';
import './Stamp.Module.css';

const imagenesBottom = [
	{ image: beautiComunidad, name: 'Beauti comunidad', link: '/alianzas' },
	{ image: beautiBox, name: '#Beautiboxes', link: '' },
	{ image: giftCards, name: '#Giftcards', link: '' },
	// { image: alianzas, name: 'Alianzas', link: '/alianzas' },
];

function StampBottom() {
	return (
		<div className='container'>
			<div className='row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 py-5 px-5'>
				{imagenesBottom?.map((imgStamp, index) => {
					return (
						<div key={index} className='py-5'>
							<Stamp imgStamp={imgStamp} />
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default StampBottom;
