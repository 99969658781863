import React from 'react';
import { Link } from 'react-router-dom';
import './Card.Module.css';
import product1 from "../../assets/images/CORAZON-VERDE-TRANS.png"
// import product1 from '../../assets/PRODUCTO_SIN_FONDO.png';
import product2 from "../../assets/images/CORAZON-VERDE-TRANS-FONDO.png"
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from '../../utils/auth/auth';
import christmas from "../../assets/images/beautisale.jpg"
import gangas from "../../assets/images/gangas3.png"

import { Box, darken, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import Carousel from 'react-multi-carousel';

import { agregarAlCarrito } from '../../features-slice/cart/carritoSlice';
import ModalEditProduct from '../modals/modalEditProduct/ModalEditProduct';
import { currencyDisplay } from '../../utils/cart/cart';
import { isNewProduct } from '../../utils/products/products';
import vegan from '../../assets/Sellos/VEGAN.png';
import toast from 'react-hot-toast';
import axios from 'axios';


function Card({ sale, isMobile, termino, color }) {

	
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const dispatch = useDispatch();
	const [showModal, setShowModal] = React.useState(false);
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';

	// Como mostrar las imagenes con el paso del mouse por encima
	const img1 = sale.img ? sale.img : product1;
	const img2 = sale.img2 ? sale.img2 : product2;
	const [cambioImg, setCambioImg] = React.useState(false);
	const handleMouseEnter = () => {
		setCambioImg(true);
	};
	const handleMouseLeave = () => {
		setCambioImg(false);
	};
	const [showVariantSelector, setShowVariantSelector] = React.useState(false);
	const [selectedVarianteId, setSelectedVarianteId] = React.useState('');

	const isMobileScreen = useMediaQuery({ maxWidth: 768 }); // Cambio de imagen en pantallas pequeñas
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	function manejadorAgregarACarrito() {
	// console.log("producto", sale)
	// console.log("variante", selectedVarianteId)
		if(sale.variantes?.length > 0 && !selectedVarianteId ){
			toast.error("Debes seleccionar una variante antes de agregar al carrito")
		}
		else if (selectedVarianteId) {
			const carritoItem = {
				...sale,
				cantidad: 1,
				varianteId: selectedVarianteId,
			};

			dispatch(agregarAlCarrito(carritoItem));
		} else {
			dispatch(agregarAlCarrito({ ...sale, cantidad: 1 }));
		}
	}

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedVarianteId('');
	};

	const handleSaveData = async (formData) => {
		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/productos/${sale._id}`,
				formData,
				config
			);
			const event = new Event('actualizar-producto');
			window.dispatchEvent(event);

			toast.success('Producto actualizado con éxito');
		} catch (error) {
			toast.error('Error al actualizar producto');
		}
	};
	const [imageLoading, setImageLoading] = React.useState(true);
  
// Función para procesar la cadena
const procesarCadena = (cadena) => {
  if (cadena && cadena[0].match(/\d/)) {
    return cadena.slice(1);
  } else {
    return cadena;
  }
};
  

    const handleImageLoad = () => {
        setImageLoading(false);
    };

	return (
		<div
		
			className={`card cardcitita ${cambioImg ? 'hovered' : ''} shadow containerImage ${
				isMobile ? 'mobile-card' : '' // Aplica la clase 'mobile-card' si isMobile es verdadero
			}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>

			{isSuperAdmin && sale?._id && (
				<div style={{position:"absolute", zIndex:10}}className='edit-icon-card' onClick={handleOpenModal}>
					<abbr title={`editar ${sale.nombre}`}>
						<i className='bi bi-pencil'></i>
					</abbr>
				</div>
			)}
				<div className='ofertas'>
					{sale.precioOferta > 0 && 
					<img className='christmas' src={gangas} alt='Navidad' />
					// 	<span className='badge  new-sale'>
					// 		#BEAUTI FRIDAY
					// </span>
					}
					{sale.fechaCreacion
						? isNewProduct(sale.fechaCreacion) && (
							<span className='badge bg-info text-white new-sale'>NEW</span>
						)
						: ''}
					{sale.vegan && (
						<abbr title='Producto vegano'>
							<img className='vegan' src={vegan} alt='Vegano' />
							
						</abbr>
					)}
				</div>
							<Link className='btn btn-sm card linkDetail' to={`/producto/${sale._id}?termino=${termino}`} disabled>
						{isMobileScreen ? (
							<Carousel
								className={`cardCarousel`}
								draggable={true}
								showDots={true}
								swipeable={true}
								responsive={responsive}
								infinite={true}
								autoPlay={false}
								keyBoardControl={true}
								//autoPlay={props.deviceType !== 'mobile' ? true : false}
								autoPlaySpeed={4000}
								containerClass='carousel-container'
								removeArrowOnDeviceType={['tablet', 'mobile']}
							>
								<img src={isMobileScreen && img1} alt={`Imagen de`} className={`cardCarouselImg`} style={{height:isMobileScreen&&"100%", objectFit:isMobile&&"cover"}} />
								<img src={isMobileScreen && img2} alt={`Imagen de`} className={`cardCarouselImg`} style={{height:isMobileScreen&&"100%",objectFit:isMobile&&"cover"}}/>
							</Carousel>
						) : (
							<img
						
								src={cambioImg ? img2 : img1}
								alt={`Imagen de `}
								className={`bd-placeholder-img card-img-top cardcita `}
							/>
						)}
					</Link>
				
					<div className='card-body contenedorPreciosBotones'>
						<Link className='btn btn-sm  linkDetail' to={`/producto/${sale._id}`} disabled>
							<abbr
								title={sale.descripcion}
								style={{
									display: 'inline-block',
									cursor: 'pointer',
									width: '2vw !important',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'wrap',
								}}
							>
								<p style={{fontFamily:"Montserrat", fontSize:isMobile?"9px":"11px", color:"#5c5c5c"}} className='tituloCard'>{procesarCadena(sale?.nombre)}</p>
							</abbr>
							{/* <p className='card-text'>{`${sale.descripcion.split(' ').slice(0, 6).join(' ')}...`}</p> */}
				</Link>

				<div className='d-flex flex-column justify-content-between align-items-center '>
								<small style={{fontFamily:"Montserrat",fontSize:isMobileScreen?"8px":"10px"}}>{sale?.marca}</small>
					{sale.precioOferta > 0 ? (
						<abbr
							style={{ cursor: 'pointer', textDecoration: 'none' }}
							title={`precio en efectivo: ${currencyDisplay(sale?.precioEfectivo)}`}
						>
							<small className='text-muted oferta'>
								<s>{currencyDisplay(sale.precioTarjeta)}</s> {currencyDisplay(sale.precioOferta)}
							</small>
						</abbr>
					) : (
						<abbr
							style={{ cursor: 'pointer', textDecoration: 'none' }}
							title={`precio en efectivo: ${currencyDisplay(sale?.precioEfectivo)}`}
						>
							<small style={{fontSize:isMobile?"12px":"16px", fontFamily:"Montserrat"}} className='text-muted '><b>{currencyDisplay(sale.precioTarjeta)}</b></small>
						</abbr>
					)}
					<div className='d-flex justify-content-between align-items-center'>
						
							<div>
								{sale.variantes.length === 0 ? (
									<Box 
									onClick={()=>manejadorAgregarACarrito()}
									sx={{	
											
										width:isMobile?"100px":"120px",
												height:"45px", 
												backgroundColor:color ? color : "#E9CED2", 
												display:"flex", 
												justifyContent:"center", 
												alignItems:"center",
												cursor:"pointer",
					
												transition: "all 0.3s ease",
												boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
								
												"&:hover": {
								backgroundColor: color ? darken(color, 0.2) : "#red",
								boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
							},
								
												"&:active": {
														transform: "scale(0.95)",
												}
												}}>
									<Typography className='goBeautiGangas' sx={{color:color?"white":"#5c5c5c", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
										Comprar
									</Typography>
						
								</Box>
								
								) : (
									<>
										{!selectedVarianteId && !showVariantSelector && (
											<Box 
											onClick={() => setShowVariantSelector(true)}
											sx={{	
													
														width:isMobile?"100px":"120px", 
														height:"45px", 
														backgroundColor:color ? color : "#E9CED2", 
														display:"flex", 
														justifyContent:"center", 
														alignItems:"center",
														cursor:"pointer",
							
														transition: "all 0.3s ease",
														boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
										
														"&:hover": {
										backgroundColor: color ? darken(color, 0.2) : "#red",
										boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
									},
										
														"&:active": {
																transform: "scale(0.95)",
														}
														}}>
											<Typography className='goBeautiGangas' sx={{color:color?"white":"#5c5c5c", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
												Comprar
											</Typography>
								
										</Box>
										
										)}

										{showVariantSelector && sale.variantes.length > 0 && (
											<div style={{display:"flex",flexDirection:"row",gap:"5px",marginLeft:isMobile?"":"", justifyContent:"center", alignItems:"center"}}>
												<select
												style={{
													fontFamily:"Montserrat",
													maxWidth:"100px",width:isMobile&&"60px", fontSize:isMobile?"8px":"10px", height:isMobile?"30px":"40px", borderRadius:"5px", border:"1px solid #d9d9d9"}}
													value={selectedVarianteId}
													onChange={(e) => setSelectedVarianteId(e.target.value)}
												>
													<option style={{color:"#5c5c5c", fontSize:isMobile?"10px":"12px", fontFamily:"Montserrat"}} value=''>Variante</option>
													{sale.variantes
														.filter((variante) => variante.disponibles > 0) // Filtrar por disponibilidad mayor a 0
														.map((variante) => (
															<option style={{color:"#5c5c5c", fontSize:isMobile?"8px":"10px", fontFamily:"Montserrat"}} key={variante._id} value={variante._id}>
																{variante.color}
															</option>
														))}
												</select>
												<button
													className="btn btn-sm btn-outline-secondary carrito"
													onClick={()=>manejadorAgregarACarrito()}											
													
												>
													<small
														style={{
															fontSize: isMobile ? "8px" : "10px",
															fontFamily: "Poppins",
														}}
														className="text-muted"
													>
														Agregar
													</small>
												</button>
											</div>
										)}
									</>
								)}
							</div>
						
					</div>
				</div>
			</div>
			{showModal && (
				<ModalEditProduct
					show={showModal}
					handleClose={handleCloseModal}
					sale={sale?._id && sale}
					handleSave={handleSaveData}
				/>
			)}
		</div>
	);
}

export default Card;
