import React from 'react';
import { Table } from 'react-bootstrap';
import { formatDate } from '../../../utils/validate/validate';
import { currencyDisplay } from '../../../utils/cart/cart';
import './PurchasesWebTable.Module.css';
import mp from "../../../assets/mplogo.png"

const PurchasesWebTable = ({ purchases, onPurchaseClick }) => {
	const [selected, setSelected] = React.useState(null);

	const handlePurchaseClick = (purchase) => {
		setSelected(purchase);
		onPurchaseClick(purchase);
	};
console.log(purchases)
	return (
		<Table responsive="sm" striped bordered hover>
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Cliente</th>
					<th>Total</th>
					<th>Estado</th>
				</tr>
			</thead>
			<tbody>
				{purchases?.map((purchase) => (
					<tr key={purchase._id} onClick={() => handlePurchaseClick(purchase)}>
						<td className={selected?._id === purchase._id ? 'selected' : ''}>
							{formatDate(purchase.fecha)}
						</td>
						<td
							className={selected?._id === purchase._id ? 'selected' : ''}
						>{`${purchase.cliente?.nombre.toUpperCase()} ${purchase.cliente?.apellido.toUpperCase()}`}</td>
						<td className={selected?._id === purchase._id ? 'selected' : ''}>
							{currencyDisplay(purchase.total)}
						</td>
						<td className={`${purchase.estadoCompra.toLowerCase()}-state`}>
    {purchase.estadoCompra}
    {purchase.metodoPago === "MERCADO_PAGO" && (
      <img src={mp} alt="MercadoPago logo" style={{ height:"35px",marginLeft: '8px', verticalAlign: 'middle' }} />
    )}
  </td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default PurchasesWebTable;
