import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { Form, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es';
import axios from 'axios';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import Loader from '../../components/loader/Loader';
import toast, { Toaster } from 'react-hot-toast';
import './CajaDiaria.Module.css';
import { Typography, Box } from '@mui/material';

import ModalNuevoEgreso from '../../components/modals/modalNuevoEgreso/ModalNuevoEgreso';
import ModalNuevoIngreso from '../../components/modals/modalNuevoIngreso/ModalNuevoIngreso';
import ModalAbrirCaja from '../../components/modals/modalAbrirCaja/ModalAbrirCaja';
import ModalCerrarCaja from '../../components/modals/modalCerrarCaja/ModalCerrarCaja';
import ModalEditarCaja from '../../components/modals/modalEditarCaja/ModalEditarCaja';
import DetalleCaja from './DetalleCaja';

const CajaDiaria = () => {

	const isMobile = useMediaQuery({ maxWidth: 768 });
	const isMobileSm = useMediaQuery({ maxWidth: 500 });


	const [showEgresoModal, setShowEgresoModal] = useState(false);
	const [showIngresoModal, setShowIngresoModal] = useState(false);
	const [showAbrirCajaModal, setShowAbrirCajaModal] = useState(false);
	const [showCerrarCajaModal, setShowCerrarCajaModal] = useState(false);
	const [showEditarCajaModal, setShowEditarCajaModal] = useState(false);

	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';

	const [showDateSelector, setShowDateSelector] = useState(false);
	const [cierreDate, setCierreDate] = useState('');
	const [cajaDelDia, setCajaDelDia] = useState({}); // Estado para almacenar estadísticas

	const [loader, setLoader] = useState(true);


	const uri = process.env.REACT_APP_BACK_URL;
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	//----------------------handlers para visualizar un detalle de caja-------------------------

	const handleShowDateSelector = () => {
		setShowDateSelector(true);
	};
	const handleCancelShowDateSelector = () => {
		setShowDateSelector(false);
	};

	const handleGetCierre = async () => {
		setCajaDelDia({});
		let fecha = '';
		if (!cierreDate) {
			return toast.error('Debes seleccionar una fecha para visualizar el cierre');
		}

		if (cierreDate) {
			fecha = format(cierreDate, 'yyyy-MM-dd');
		}

		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/cajadiaria?fechaDesde=${fecha}`,

				config
			);

			if (data.cierre !== null) {
				toast.success('Cierre de caja encontrado');
			} else {
				toast.error('cierre de caja no encontrado');
			}
			setCajaDelDia(data);
			setCierreDate('');
			// Disparar un evento personalizado 'actualizar-producto' cuando se haya actualizado el producto
			const event = new Event('actualizar-caja');
			window.dispatchEvent(event);
		} catch (error) {
			console.log(error);
			toast.error(error.response.data?.msg);
		}
	};

	//-------------------------------handlers para modales------------------------
	const handleShowAbrirCajaModal = () => setShowAbrirCajaModal(true);

	const handleCloseAbrirCajaModal = () => {
		setShowAbrirCajaModal(false);
	};

	const handleShowEgresoModal = () => setShowEgresoModal(true);

	const handleCloseEgresoModal = () => {
		setShowEgresoModal(false);
	};

	const handleShowIngresoModal = () => setShowIngresoModal(true);

	const handleCloseIngresoModal = () => {
		setShowIngresoModal(false);
	};

	const handleShowCerrarCajaModal = () => setShowCerrarCajaModal(true);

	const handleCloseCerrarCajaModal = () => {
		setShowCerrarCajaModal(false);
	};

	const handleShowEditarCajaModal = () => setShowEditarCajaModal(true);

	const handleCloseEditarCajaModal = () => {
		setShowEditarCajaModal(false);
	};
	//---------------------------------------------------------------------------

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 1000);
	}, []);

	return (
		<div className='fondoStats'>
			<Box className="headerMarket">
				<NavBar />
			</Box>
			{loader ? (
				<Loader />
			) : (
				<main className='mainCajaDiaria'>
					<Toaster
						position='bottom-left'
						reverseOrder={false}
						toastOptions={{ className: 'fondoToast' }}
					/>
					<h2>Caja diaria</h2>
					{cajaDelDia && cajaDelDia.saldoInicial ? (
						<>
							<div className='containerDateStats'>
								<h3>Resumen de la caja diaria</h3>
								<p>Saldo Inicial: {cajaDelDia.saldoInicial}</p>
								{/* Agrega aquí más información de la caja diaria si es necesario */}
							</div>
						</>
					) : (
						<div>
							<div style={{ gap:"15px"}} className='buttonsContainerCaja row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 py-5 px-5'>
								<Button variant='primary' onClick={handleShowAbrirCajaModal}>
									Abrir caja
								</Button>
								<Button variant='danger' onClick={handleShowEgresoModal}>
									Egreso
								</Button>
								<Button variant='success' onClick={handleShowIngresoModal}>
									Ingreso
								</Button>
								<Button variant='secondary' onClick={handleShowCerrarCajaModal}>
									Cerrar caja
								</Button>
								<Button variant='info' onClick={handleShowDateSelector}>
									Ver detalle
								</Button>
								{isSuperAdmin && (
									<Button variant='warning' onClick={handleShowEditarCajaModal}>
										Editar caja
									</Button>
								)}
							</div>
							{showDateSelector && (
								<div className='dateSelector'>
									<label>Selecciona la fecha del cierre de caja que quieres visualizar</label>
									<div style={{ position: 'relative' }}>
										<i
											className='bi bi-x-circle filterOptionXnew'
											onClick={() => handleCancelShowDateSelector()}
										></i>
										<DatePicker
											className='form-control shadow picker'
											selected={cierreDate}
											onChange={(date) => setCierreDate(date)}
											locale={es}
											dateFormat='dd-MM-yyyy' // Establece el formato personalizado
										/>
										<Button
											variant='success'
											style={{ position: 'absolute', bottom: 0, right: 0 }}
											onClick={(e) => handleGetCierre()}
										>
											Obtener Info
										</Button>
									</div>
								</div>
							)}

							{/* {cajaDelDia.cierre && <DetalleCaja cajaData={cajaDelDia?.cierre} />} */}
							{cajaDelDia.cierre && <DetalleCaja cajaData={cajaDelDia?.cierre} />}
						</div>
					)}
				</main>
			)}
			<footer>
				<Footer />
			</footer>
			{showEgresoModal && (
				<ModalNuevoEgreso show={showEgresoModal} handleClose={handleCloseEgresoModal} />
			)}
			{showIngresoModal && (
				<ModalNuevoIngreso show={showIngresoModal} handleClose={handleCloseIngresoModal} />
			)}
			{showAbrirCajaModal && (
				<ModalAbrirCaja show={showAbrirCajaModal} handleClose={handleCloseAbrirCajaModal} />
			)}
			{showCerrarCajaModal && (
				<ModalCerrarCaja show={showCerrarCajaModal} handleClose={handleCloseCerrarCajaModal} />
			)}
			{showEditarCajaModal && (
				<ModalEditarCaja show={showEditarCajaModal} handleClose={handleCloseEditarCajaModal} />
			)}
		</div>
	);
};

export default CajaDiaria;
